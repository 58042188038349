import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../Title';
import Cnt from '../Cnt';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: 8,
    paddingRight: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 62,
  },
}));

export default function Movers(props) {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Title>Грузчики</Title>
      <Cnt {...props} />
    </div>
  );
}
