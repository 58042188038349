import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  block: {
    background: '#ffffff',
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 10,
    position: 'relative',
    transition: '.3s',
    width: 'calc(100% - 0px)',
  },
  blockSmall: {
    minHeight: 83,
  },
  blockLarge: {
    minHeight: 170,
  },
  blockActive: {
    // background: '#FFFFFF',
    // boxShadow: '0px 2px 80px rgba(37, 38, 49, 0.05)',
    // borderColor: 'transparent',
    // padding: '22px 0',
  },
  error: {
    borderColor: '#FE5454',
    animationName: '$error',
    animationDuration: '.3s',
  },
  borderNone: {
    border: 'none',
  },
  '@keyframes error': {
    '0%': {
      transform: 'translateX(-5px)',
    },
    '25%': {
      transform: 'translateX(3px)',
    },
    '50%': {
      transform: 'translateX(-3px)',
    },
    '75%': {
      transform: 'translateX(-2px)',
    },
    '100%': {
      transform: 'translateX(0px)',
    },
  },
}));

export default function Block({
  alignItems = 'center',
  size,
  active,
  error,
  borderNone,
  className,
  ...props
}) {
  const classes = useStyles();

  return (
    <Grid
      {...props}
      container
      alignItems={alignItems}
      className={clsx(classes.block, className, {
        [classes.blockSmall]: size === 'small',
        [classes.blockLarge]: !size || size === 'large',
        [classes.blockActive]: active,
        [classes.error]: error,
        [classes.borderNone]: borderNone,
      })}
    />
  );
}
