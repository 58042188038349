import React from 'react';
import SendCode from './SendCode';
import EnterCode from './EnterCode';

export default function Phone(props) {
  return (
    <>
      <SendCode {...props} />
      <EnterCode {...props} />
    </>
  );
}
