import React, { useCallback, useReducer, useState, useEffect } from 'react';
import Address from './Address';
import Options from './Options';
import Timing from './Timing';
import Confirm from './Confirm';
import Loading from './Loading';
import CalcType from './CalcType';
import Phone from './Phone';
import Map from './Map';
import Result from './Result';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import GridSpacingSmall from './GridSpacingSmall';
import Block from './Block';
import {
  loadedReducer,
  initialLoadedStates,
  calcParamsReducer,
  initialParamsStates,
  initialOrderStates,
  orderReducer,
  housesQueryReducer,
  initialHousesQueryStates,
  initialStreetsQueryStates,
  streetsQueryReducer,
  initialLocStates,
  locReducer,
  markersReducer,
  initialMarkersStates,
} from '../lib/reducer';
import { useQuery } from '@apollo/react-hooks';
import {
  GET_PRICE_QUERY,
  GET_NEAREST_CARS,
  GET_DELIVERY_TIME,
} from '../lib/query';
import { getPriceParams } from '../lib/functions';
import Price from './Price';
import clsx from 'clsx';
import Bill from './Bill';

function getRandomIntInclusive(max) {
  const min = 1;
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const useStyles = makeStyles(theme => ({
  calc: {
    maxWidth: '100%',
    width: '100%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    // overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
  },
  positionRight: {
    justifyContent: 'flex-end',

    '& $map': {
      left: 0,
      right: 'auto',
    },
  },
  positionLeft: {
    justifyContent: 'flex-start',

    '& $map': {
      right: 0,
      left: 'auto',
    },
  },
  devCalc: {
    maxWidth: 1280,
  },
  wrap: {
    maxWidth: 1280,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
    [theme.breakpoints.down('sm')]: {
      // overflow: 'hidden',
    },
    transition: '.3s',
  },
  column: {
    '&$calc': {
      [theme.breakpoints.up('sm')]: {
        position: 'static',
      },
    },

    '& $wrap': {
      maxWidth: 404,
      [theme.breakpoints.up('sm')]: {
        position: 'static',
      },
    },

    '& $address': {
      width: '100%',
      position: 'relative',
    },

    '& $map': {
      marginTop: 0,
      position: 'absolute',
      width: 'calc(100% - 420px)',
      height: '100%',
    },
  },
  devWrap: {
    [theme.breakpoints.up('sm')]: {
      padding: '87px 24px 87px 24px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  root: {
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      // margin: 'auto',
      // width: '100%',
      position: 'relative', //fix for mobile fleet
    },
  },
  addressWrap: {
    position: 'relative',
    zIndex: 1,
  },
  address: {
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
    },
  },
  timing: {
    [theme.breakpoints.up('sm')]: {
      // position: 'relative',
    },
  },
  phone: {
    position: 'relative',
    minHeight: 81,
  },
  confirmWrap: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
    },
  },
  map: {},
}));

export default function AppContent({
  className,
  classes: classesProps,
  style,
  city,
  carId,
  duration,
  direction,
  position,
  dev,
  orderEmailTo,
}) {
  const classes = useStyles();

  const [loaded, dispatchLoaded] = useReducer(
    loadedReducer,
    initialLoadedStates,
  );
  const [calcParams, dispatchCalcParams] = useReducer(
    calcParamsReducer,
    initialParamsStates,
  );

  const [orderParams, dispatchOrderParams] = useReducer(
    orderReducer,
    initialOrderStates,
  );
  const [markers, dispatchMarkers] = useReducer(
    markersReducer,
    initialMarkersStates,
  );
  const [streetsQuery, dispatchStreetsQuery] = useReducer(
    streetsQueryReducer,
    initialStreetsQueryStates,
  );
  const [housesQuery, dispatchHousesQuery] = useReducer(
    housesQueryReducer,
    initialHousesQueryStates,
  );
  const [loc, dispatchLoc] = useReducer(locReducer, initialLocStates);

  const allLoaded = loaded.options && loaded.carClasses;

  const handleLoaded = useCallback(type => {
    dispatchLoaded({ type });
  }, []);

  const handleCalcParams = useCallback(options => {
    dispatchCalcParams(options);
  }, []);

  const handleOrderParams = useCallback(payload => {
    dispatchOrderParams({ type: 'add', payload });
  }, []);

  const initialInputFieldsStates = {
    0: {
      index: 0,
    },
    1: {
      index: 1,
    },
  };

  function inputFieldsReducer(state, action) {
    switch (action.type) {
      case 'change':
        return { ...state, ...action.payload };
      case 'delete':
        const index = action.payload;
        if (delete state[index]) {
          dispatchLoc({
            type: 'delete',
            payload: index,
          });
          dispatchStreetsQuery({
            type: 'delete',
            payload: index,
          });
          dispatchHousesQuery({
            type: 'delete',
            payload: index,
          });
          dispatchMarkers({
            type: 'delete',
            payload: index,
          });
        }
        return { ...state };
      default:
        throw new Error();
    }
  }
  const [inputFields, dispatchInputFields] = useReducer(
    inputFieldsReducer,
    initialInputFieldsStates,
  );

  const [error, setError] = useState(null);
  const [price, setPrice] = useState(0);
  const [querySkip, setQuerySkip] = useState(true);
  const [data, setData] = useState(null);
  const [openMap, setOpenMap] = useState(false);
  const [fleetOpen, setFleetOpen] = useState(false);
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [focusInputLine, setFocusInputLine] = useState(null);

  const [phone, setPhone] = useState('');
  const [phoneFull, setPhoneFull] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const [status, setStatus] = useState(null);

  const [nearestCarsSkip, setNearestCarsSkip] = useState(true);
  const [nearestCarsData, setNearestCarsData] = useState(null);

  const [deliveryLoc, setDeliveryLoc] = useState([]);
  const [deliveryTimeSkip, setDeliveryTimeSkip] = useState(true);
  const [deliveryTimeData, setDeliveryTimeData] = useState(null);

  const {
    loc: calcLoc,
    options,
    order_date,
    ordertime,
    is_now2,
    json_data,
  } = calcParams;

  const { loading, error: priceError } = useQuery(GET_PRICE_QUERY, {
    skip: querySkip,
    fetchPolicy: 'network-only',
    variables: {
      city: city.id,
      loc: calcLoc,
      options,
      order_date,
      ordertime,
      json_data,
      discount: city.discount ? city.discount : 0,
    },
    onCompleted: data => {
      setData(data);
      setQuerySkip(true);
    },
  });

  useQuery(GET_NEAREST_CARS, {
    skip: nearestCarsSkip,
    fetchPolicy: 'network-only',
    variables: {
      city: city.id,
      lat: !!calcLoc.length && parseFloat(calcLoc[0].split(',')[0]),
      lng: !!calcLoc.length && parseFloat(calcLoc[0].split(',')[1]),
    },
    onCompleted: data => {
      setNearestCarsData(
        data && data.getNearestCars && data.getNearestCars.nearest_cars,
      );
      setNearestCarsSkip(true);
    },
  });

  useQuery(GET_DELIVERY_TIME, {
    skip: deliveryTimeSkip,
    fetchPolicy: 'network-only',
    variables: {
      city: city.id,
      loc: deliveryLoc,
    },
    onCompleted: data => {
      setDeliveryTimeData(
        data &&
          data.getDeliveryTime &&
          data.getDeliveryTime.obj &&
          data.getDeliveryTime.obj.distances,
      );
      setDeliveryTimeSkip(true);
    },
  });

  useEffect(() => {
    const gr_cnt_porter = json_data.porters;
    const gr_cnt_pass = json_data.passengers;
    const order_type_auto = json_data.car_id;
    const rand_id = getRandomIntInclusive(order_type_auto);
    const trans_id = getRandomIntInclusive(parseFloat(ordertime));

    handleOrderParams({
      gr_cnt_porter,
      gr_cnt_pass,
      order_type_auto,
      order_date,
      order_options: options,
      order_time: ordertime,
      is_now2,
      rand_id,
      trans_id,
    });
  }, [handleOrderParams, json_data, options, order_date, ordertime, is_now2]);

  useEffect(() => {
    if (calcLoc.length > 1) {
      setQuerySkip(false);
    }
    return () => {
      if (data) {
        setQuerySkip(true);
      }
    };
  }, [calcLoc, json_data, data]);

  useEffect(() => {
    if (calcLoc.length > 1) {
      setNearestCarsSkip(false);
    }
    return () => {
      setNearestCarsSkip(true);
    };
  }, [calcLoc, json_data.car_id, calcParams.ordertime]);

  useEffect(() => {
    const nearestCars =
      nearestCarsData &&
      nearestCarsData.filter(car => car.id_type_auto === json_data.car_id);

    if (nearestCars && !!nearestCars.length > 0) {
      const newDeliveryLoc = [calcLoc[0]];
      nearestCars.forEach(el => {
        newDeliveryLoc.push(`${el.lat},${el.lng}`);
      });
      if (newDeliveryLoc.length > 1) {
        setDeliveryLoc(newDeliveryLoc);
        setDeliveryTimeSkip(false);
      }
    } else {
      setDeliveryTimeData(null);
    }
    return () => {
      setDeliveryTimeSkip(true);
    };
  }, [json_data.car_id, nearestCarsData, calcLoc]);

  useEffect(() => {
    if (!!deliveryTimeData) {
      const newDeliveryTime = [];
      deliveryTimeData.forEach(el => {
        newDeliveryTime.push(parseFloat(el.time));
      });

      if (Math.min.apply(null, newDeliveryTime)) {
        handleCalcParams({
          type: 'add',
          payload: {
            delivery_time: Math.floor(
              Math.min.apply(null, newDeliveryTime) / 60,
            ),
          },
        });
      }
    } else {
      handleCalcParams({
        type: 'add',
        payload: {
          delivery_time: city.minDeliveryTime || 15,
        },
      });
    }
  }, [
    deliveryTimeData,
    handleCalcParams,
    json_data.car_id,
    city.minDeliveryTime,
  ]);

  useEffect(() => {
    if (data) {
      const { getPrice } = data;
      if (getPrice.prices[json_data.car_id]) {
        const {
          order_c_auto,
          jtoken_price_gr,
          discount_proc_used,
          dist,
          price_hash,
          promo_discount_price,
          price_add_hour_totla,
        } = getPriceParams(getPrice, json_data.car_id);

        setPrice(order_c_auto);

        handleOrderParams({
          jtoken_price_gr,
          discount_proc_used,
          dist,
          price_hash,
          promo_discount_price,
          order_c_auto,
          price_add_hour_totla,
        });
      } else {
        setPrice(0);
      }
    }
    return () => {
      setQuerySkip(true);
    };
  }, [data, json_data.car_id, handleOrderParams]);

  function onReset() {
    setStatus(null);
    dispatchCalcParams({ type: 'reset' });
    dispatchOrderParams({ type: 'reset' });
    dispatchStreetsQuery({ type: 'reset' });
    dispatchHousesQuery({ type: 'reset' });
    dispatchLoc({ type: 'reset' });
    dispatchMarkers({ type: 'reset' });
    setPrice(0);
    setPhone('');
    setPhoneFull(false);
    setCodeSent(false);
  }

  function checkError(error) {
    if (error && (error.sendCode || error.checkCode)) {
      return true;
    }
    return false;
  }

  const { lat, lng } = city;
  const currentPosition = [lng, lat];

  function getCenter() {
    if (
      loc &&
      loc[focusInputLine] &&
      loc[focusInputLine].lngLat &&
      typeof loc[focusInputLine].lngLat === 'object'
    ) {
      const lngLat = {
        lng: loc[focusInputLine].lngLat.lng,
        lat: loc[focusInputLine].lngLat.lat + 0.0004,
      };
      return lngLat;
    }
    return currentPosition;
  }

  return (
    <div
      className={clsx(classes.calc, {
        [classes.devCalc]: dev,
        [classes.column]: direction === 'column',
        [classes.positionRight]: direction === 'column' && position === 'right',
        [classes.positionLeft]: direction === 'column' && position === 'left',
      })}
    >
      <div
        className={clsx(className, classes.wrap, {
          [classes.devWrap]: dev,
        })}
        style={style}
      >
        <CalcType
          city={city.id}
          enableOptions={handleCalcParams}
          onLoad={handleLoaded}
          setStatus={setStatus}
        />
        <GridSpacingSmall
          className={clsx(classes.root, classesProps && classesProps.root)}
          justify="center"
        >
          <Grid
            item
            md={direction === 'column' ? 12 : 4}
            xs={12}
            className={classes.addressWrap}
          >
            <Address
              className={classes.address}
              setOpenMap={setOpenMap}
              setLoc={handleCalcParams}
              setOrderAddresses={handleOrderParams}
              error={error}
              setError={setError}
              streetsQuery={streetsQuery}
              housesQuery={housesQuery}
              loc={loc}
              dispatchStreetsQuery={dispatchStreetsQuery}
              dispatchHousesQuery={dispatchHousesQuery}
              dispatchLoc={dispatchLoc}
              focusInputLine={focusInputLine}
              setFocusInputLine={setFocusInputLine}
              dispatchMarkers={dispatchMarkers}
              inputFields={inputFields}
              dispatchInputFields={dispatchInputFields}
              city={city}
              dev={dev}
            />
          </Grid>
          <Grid item md={direction === 'column' ? 12 : 4} xs={12}>
            <Options
              onLoad={handleLoaded}
              setCarParams={handleCalcParams}
              fleetOpen={fleetOpen}
              setFleetOpen={setFleetOpen}
              defaultCarId={carId}
              city={city.id}
              setStatus={setStatus}
              dev={dev}
              hideGazon={!!orderEmailTo}
            />
          </Grid>
          <Grid item md={direction === 'column' ? 12 : 4} xs={12}>
            <GridSpacingSmall container className={classes.timing}>
              <Timing
                setTimingParams={handleCalcParams}
                openDateTimePicker={openDateTimePicker}
                setOpenDateTimePicker={setOpenDateTimePicker}
                duration={city.id === 49 ? 30 : duration}
                variants={city.id === 49 && [30]}
                deliveryTime={calcParams.delivery_time}
              />
              <Grid item xs={12}>
                <Block
                  size="small"
                  className={classes.confirmWrap}
                  error={checkError(error)}
                >
                  <Grid item md={6} xs={12} className={classes.phone}>
                    <Phone
                      setUserCode={handleOrderParams}
                      error={error}
                      setError={setError}
                      phoneFull={phoneFull}
                      setPhoneFull={setPhoneFull}
                      codeSent={codeSent}
                      setCodeSent={setCodeSent}
                      phone={phone}
                      setPhone={setPhone}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Confirm
                      loading={loading || !!priceError}
                      price={price}
                      orderParams={orderParams}
                      error={error}
                      setError={setError}
                      phone={phone}
                      codeSent={codeSent}
                      setCodeSent={setCodeSent}
                      setStatus={setStatus}
                      city={city}
                      orderEmailTo={orderEmailTo}
                    />
                  </Grid>
                </Block>
              </Grid>
            </GridSpacingSmall>
          </Grid>
          <Price price={price} />
          <Loading open={!allLoaded} dev={dev} />
          <Result
            status={status || priceError}
            onReset={onReset}
            city={city}
            dev={dev}
            direction={direction}
          />
          <Bill
            status={status === 'success'}
            onReset={onReset}
            city={city}
            direction={direction}
            orderParams={orderParams}
            calcParams={calcParams}
          />
        </GridSpacingSmall>
        {openMap && (
          <Map
            classes={classesProps}
            markers={markers}
            dispatchMarkers={dispatchMarkers}
            setOpenMap={setOpenMap}
            open={openMap}
            loc={loc}
            dispatchStreetsQuery={dispatchStreetsQuery}
            dispatchHousesQuery={dispatchHousesQuery}
            dispatchLoc={dispatchLoc}
            focusInputLine={focusInputLine}
            dispatchInputFields={dispatchInputFields}
            setFocusInputLine={setFocusInputLine}
            currentPosition={getCenter}
            setStatus={setStatus}
            className={classes.map}
            enableDev={dev}
          />
        )}
      </div>
    </div>
  );
}
