import React, { useState, useEffect, useCallback } from 'react';
import Select from './Select';
import Movers from './Movers';
import Passengers from './Passengers';
import FleetList from './FleetList';
import Grid from '@material-ui/core/Grid';
import Block from '../Block';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { heightType } from '../../lib/convert';
import clsx from 'clsx';
import { GET_FLEET_QUERY } from '../../lib/query';
import GridSpacingSmall from '../GridSpacingSmall';
import Button from '../ui/Button';
import Info from '../icons/Info';
import Hint from './Hint';

const useStyles = makeStyles(theme => ({
  block: {
    position: 'static',
  },
  root: {
    padding: '0 8px 8px 8px',
    height: '100%',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      position: 'relative', //fix for mobile fleet
    },
  },

  background: {
    visibility: 'hidden',
    opacity: 0,
    transition: '.3s',
    background: '#ffffff',
    borderRadius: 10,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  backgroundOpen: {
    visibility: 'visible',
    opacity: 0.7,
  },
  info: {
    minWidth: 'auto',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: '#FFFFFF',
    border: `1px solid ${theme.palette.primary.extraLight}`,
    borderRadius: 5,

    '&:hover': {
      background: '#FFFFFF',
      borderColor: theme.palette.primary.main,
    },
  },
  infoIcon: {
    color: theme.palette.primary.main,
    transition: '.3s',
  },
  infoWrap: {
    flexGrow: 0,
    maxWidth: 36,
    flexBasis: 36,
  },
  selectWrap: {
    flexGrow: 0,
    maxWidth: 'calc(100% - 36px)',
    flexBasis: 'calc(100% - 36px)',
  },
  content: {
    position: 'relative',
  },
}));

export default function Options({
  onLoad,
  setCarParams,
  fleetOpen,
  setFleetOpen,
  defaultCarId,
  city,
  setStatus,
  dev,
  hideGazon,
}) {
  const classes = useStyles();
  const [carData, setCarData] = useState(null);

  function getPorters() {
    if (city === 49) {
      return 0;
    }
    if (carData && carData.id === '38') {
      return 1;
    }
    return 2;
  }

  const [passengersCnt, setPassengersCnt] = useState(0);
  const [portersCnt, setPortersCnt] = useState(getPorters());
  const [infoOpen, setInfoOpen] = useState(false);

  const carCategoriesGruz = [];
  const carClassesGruz = [];

  const { loading, error, data } = useQuery(GET_FLEET_QUERY, {
    variables: {
      city,
    },
  });

  const getDefaultCarId = useCallback(
    id => {
      const car = carClassesGruz.find(car => car.id === id);
      if (car) {
        return car;
      }

      return carClassesGruz.find(car => car.id === '62');
    },
    [carClassesGruz],
  );

  useEffect(() => {
    if (!carData && carClassesGruz) {
      const defaultCar = getDefaultCarId(defaultCarId);
      setCarData(defaultCar);
      if (defaultCarId === '38') {
        setPortersCnt(1);
      }
    }
  }, [carClassesGruz, carData, getDefaultCarId, defaultCarId]);

  useEffect(() => {
    if (!loading && carData) {
      onLoad('carClassesLoaded');
      setCarParams({
        type: 'addJSON',
        payload: {
          car_id: carData.id,
          porters: portersCnt,
          passengers: passengersCnt,
        },
      });
      setCarParams({
        type: 'add',
        payload: {
          car_name: carData.name,
          car_length: carData.length,
          car_height: carData.height,
          car_width: carData.width,
          car_lifting: carData.lifting,
        },
      });
    }
  }, [loading, onLoad, setCarParams, carData, passengersCnt, portersCnt]);

  if (loading) return null;
  if (error) {
    setStatus('faildLoad');
    return null;
  }

  const { carClasses } = data;

  const { array: carCategoriesAll, car_classes: carClassesAll } = carClasses;

  for (const key in carCategoriesAll) {
    if (carCategoriesAll.hasOwnProperty(key)) {
      const categoryId = parseInt(carCategoriesAll[key].id, 10);
      if (categoryId !== 18 && categoryId !== 16) {
        if (hideGazon) {
          if (categoryId !== 26) {
            carCategoriesGruz.push(categoryId);
          }
        } else {
          carCategoriesGruz.push(categoryId);
        }
      }
    }
  }

  for (const key in carClassesAll) {
    if (carClassesAll.hasOwnProperty(key)) {
      const carClassesAllCategory = parseInt(carClassesAll[key].cat, 10);
      const isIsset = carCategoriesGruz.includes(carClassesAllCategory);

      if (isIsset && parseInt(carClassesAll[key].id, 10) !== 112) {
        heightType(carClassesAll[key]);
        carClassesGruz.push(carClassesAll[key]);
      }
    }
  }

  function onClose(item) {
    setFleetOpen(false);
    setCarData(item);
    setPassengersCnt(0);
    setPortersCnt(carData && item.id === '38' ? 1 : 2);
  }

  const defaultCar = getDefaultCarId(defaultCarId);
  const initCar = carData ? carData : defaultCar;

  function initPorters() {
    if (initCar.id === '38' && passengersCnt === 0) {
      return parseInt(initCar.cnt_pass) || 0;
    }
    return parseInt(initCar.cnt_pass) + 1 || 0;
  }

  if (!initCar) {
    return null;
  }

  return (
    <Block
      alignItems="stretch"
      alignContent="stretch"
      active={fleetOpen}
      className={classes.block}
    >
      <Grid item xs={12}>
        <div className={classes.root}>
          <Grid
            container
            spacing={2}
            alignContent="space-between"
            className={classes.content}
          >
            <Grid item md={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <Movers
                    allCnt={passengersCnt + portersCnt}
                    cnt={portersCnt}
                    max={initPorters()}
                    onChange={e => setPortersCnt(e)}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <Passengers
                    allCnt={passengersCnt + portersCnt}
                    cnt={passengersCnt}
                    max={
                      (parseInt(initCar.cnt_pass) &&
                        (portersCnt > 0
                          ? parseInt(initCar.cnt_pass) + 1
                          : parseInt(initCar.cnt_pass))) ||
                      0
                    }
                    onChange={e => setPassengersCnt(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <GridSpacingSmall>
                <Grid item className={classes.infoWrap}>
                  <Button
                    color="primary"
                    className={classes.info}
                    onClick={() => setInfoOpen(true)}
                  >
                    <Info className={classes.infoIcon} />
                  </Button>
                </Grid>
                <Grid item className={classes.selectWrap}>
                  <Select
                    onClick={() => setFleetOpen(true)}
                    car={initCar}
                    dev={dev}
                  />
                </Grid>
              </GridSpacingSmall>
            </Grid>
            <Hint
              name={initCar.name}
              description={initCar.description}
              open={infoOpen}
              onClose={() => setInfoOpen(false)}
            />
          </Grid>
          <FleetList
            fleet={carClassesGruz}
            open={fleetOpen}
            onClose={onClose}
            onClickAway={() => setFleetOpen(false)}
            dev={dev}
          />
        </div>
      </Grid>
      <div
        className={clsx(classes.background, {
          [classes.backgroundOpen]: fleetOpen,
        })}
      />
    </Block>
  );
}
