import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from './ui/Button';
import { useQuery } from '@apollo/react-hooks';
import {
  ADD_ORDER_QUERY,
  SEND_CODE_QUERY,
  BAD_ORDER_QUERY,
  EMAIL_ORDER_QUERY,
} from '../lib/query';

import format from 'date-fns/format';
import getTime from 'date-fns/getTime';
import addMinutes from 'date-fns/addMinutes';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 16px',

    [theme.breakpoints.down('sm')]: {
      padding: '0 16px 16px 16px',
    },
  },
  btn: {
    minWidth: 'auto',
    width: '100%',
    padding: '0 16px',
  },
  price: {
    whiteSpace: 'nowrap',
  },
  btnText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
}));

export default function Confirm({
  loading,
  price,
  error,
  setError,
  orderParams,
  setCodeSent,
  codeSent,
  phone,
  setStatus,
  city,
  orderEmailTo,
}) {
  const classes = useStyles();
  const [querySkip, setQuerySkip] = useState(true);
  const [emailOrderSkip, setEmailOrderSkip] = useState(true);
  const [codeQuerySkip, setCodeQuerySkip] = useState(true);
  const [badOrderSkip, setBadOrderSkip] = useState(true);
  const [data, setData] = useState(null);
  const [codeData, setCodeData] = useState(null);
  const [orderError, setOrderError] = useState(null);

  const { refetch } = useQuery(BAD_ORDER_QUERY, {
    skip: badOrderSkip || !orderError,
    fetchPolicy: 'network-only',
    variables: {
      error: orderError && JSON.stringify(orderError),
      order: JSON.stringify({
        city: city.id,
        discount: city.discount ? city.discount : 0,
        card_name_std: city.card_name_std ? city.card_name_std : null,
        ...orderParams,
      }),
    },
    onCompleted: () => {
      setBadOrderSkip(true);
    },
  });

  const { refetch: emailOrderRefetch } = useQuery(EMAIL_ORDER_QUERY, {
    skip: emailOrderSkip,
    fetchPolicy: 'network-only',
    variables: {
      to: orderEmailTo,
      order: JSON.stringify({
        city: city.name,
        discount: city.discount ? city.discount : 0,
        card_name_std: city.card_name_std ? city.card_name_std : null,
        ...orderParams,
      }),
    },
    onCompleted: () => {
      setEmailOrderSkip(true);
    },
  });

  const sendEmail = useCallback(
    errorQuery => {
      setOrderError(errorQuery);
      refetch();
      setBadOrderSkip(false);
    },
    [refetch],
  );

  const sendEmailOrder = useCallback(() => {
    emailOrderRefetch();
    setEmailOrderSkip(false);
  }, [emailOrderRefetch]);

  const { loading: loadingSendCode } = useQuery(SEND_CODE_QUERY, {
    skip: codeQuerySkip,
    fetchPolicy: 'network-only',
    variables: {
      city: city.id,
      user_phone: phone.replace(/\D+/g, ''),
    },
    onCompleted: data => {
      setCodeData(data);
      setCodeQuerySkip(true);
    },
  });

  const { loading: loadingConfirm, orderQueryError } = useQuery(
    ADD_ORDER_QUERY,
    {
      skip: querySkip,
      fetchPolicy: 'network-only',
      variables: {
        city: city.id,
        card_name_std: city.card_name_std ? city.card_name_std : null,
        ...orderParams,
      },
      onCompleted: data => {
        setData(data);
        setQuerySkip(true);
      },
    },
  );

  useEffect(() => {
    if (codeData) {
      switch (codeData.sendCode.status) {
        case '1':
          setCodeSent(true);
          break;
        case '-1':
          setError({
            ...error,
            sendCode: codeData.sendCode.error[0],
          });
          break;
        default:
          break;
      }
    }
    return () => {
      setQuerySkip(true);
      setData(null);
    };
  }, [codeData, setCodeSent, setError, error]);

  useEffect(() => {
    if (data) {
      switch (data.addOrder.status) {
        case '1':
          setStatus('success');
          if (orderEmailTo) {
            sendEmailOrder();
          }
          break;
        case '-1':
          setStatus('error');
          sendEmail(data.addOrder.error);
          break;
        default:
          setStatus('success');
          sendEmail(data.addOrder);
          break;
      }
    }

    return () => {
      setQuerySkip(true);
      setBadOrderSkip(true);
      setEmailOrderSkip(true);
      setData(null);
    };
  }, [data, setStatus, city, sendEmail, sendEmailOrder, orderEmailTo]);

  useEffect(() => {
    if (orderQueryError) {
      setStatus('error');
      sendEmail(orderQueryError);
    }
  }, [orderQueryError, setStatus, sendEmail]);

  function onClick() {
    if (orderParams.order_time) {
      const hours = parseInt(orderParams.order_time.slice(0, 2));
      const minutes = parseInt(orderParams.order_time.slice(-2));
      const day = parseInt(orderParams.order_date.split('-')[2]);
      const month = parseInt(orderParams.order_date.split('-')[1]) - 1;
      const year = parseInt(orderParams.order_date.split('-')[0]);

      const defaultDate = new Date();
      const currentDateTime = getTime(defaultDate);
      const newDateTime = getTime(new Date(year, month, day, hours, minutes));

      if (currentDateTime + 15 * 60000 > newDateTime) {
        const newData = addMinutes(defaultDate, 15);
        orderParams.order_time = format(newData, 'HHmm');
      }
    }

    const newError = {};
    if (orderParams && !orderParams.user_phone) {
      newError.sendCode = 'required';
    }
    if (orderParams && orderParams.user_phone && !orderParams.user_code) {
      newError.checkCode = 'required';
    }
    if (orderParams && !orderParams.order_addresses) {
      newError.address = true;
    }
    if (
      orderParams &&
      (!orderParams.user_phone ||
        (orderParams.user_phone && codeSent && !orderParams.user_code) ||
        !orderParams.order_addresses)
    ) {
      setError(newError);
    } else {
      if (codeSent) {
        setQuerySkip(false);

        if (window.gtag) {
          window.gtag('event', 'formSubmit', { event_category: 'form' });
        }
        if (window.ym) {
          window.ym(city.ymId, 'reachGoal', 'formSubmit');
        }
      } else {
        setCodeQuerySkip(false);
      }
    }
  }

  const loadedPrice = !!price ? ` за ${price} р` : '';

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      justify="center"
    >
      {loading || loadingConfirm || loadingSendCode ? (
        <Typography className={classes.btnText} color="primary" align="center">
          Загрузка...
        </Typography>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          disabled={loading || loadingConfirm || loadingSendCode}
          onClick={onClick}
        >
          <Typography className={classes.btnText}>
            {codeSent ? (
              'Подтвердить'
            ) : (
              <>
                Заказать<span className={classes.price}>{loadedPrice}</span>
              </>
            )}
          </Typography>
        </Button>
      )}
    </Grid>
  );
}
