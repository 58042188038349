import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../lib/theme';
import AppContent from './AppContent';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { getParam } from '../lib/url';
import city from '../lib/cityConfig';
import { duration as hours } from '../lib/time';

function cityData(cityId) {
  const cityData = city.find(data => data.id === cityId);
  if (cityData) {
    return cityData;
  }

  return city.find(data => data.id === 1);
}

export default function Widget({
  className,
  classes,
  cityId: cityIdProp,
  carId: carIdProp,
  duration,
  direction,
  position = 'right',
  enableDev = true,
  orderEmailTo,
}) {
  const dev = enableDev && process.env.NODE_ENV !== 'production';

  const client = new ApolloClient({
    uri: dev
      ? process.env.WIDGET_URL || '/graphql'
      : 'https://order-widget.gruzovichkof.ru/graphql',
  });

  const cityId = cityIdProp || parseInt(getParam('cityId')) || 1;
  const carId = carIdProp || getParam('carId') || 62;

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppContent
          className={className}
          classes={classes}
          city={cityData(parseInt(cityId))}
          carId={carId.toString()}
          duration={hours.indexOf(duration) !== -1 ? duration : hours[0]}
          direction={direction}
          position={position}
          dev={dev}
          orderEmailTo={orderEmailTo}
        />
      </ThemeProvider>
    </ApolloProvider>
  );
}
