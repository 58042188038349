import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { showParams, showLifting } from '../lib/convert';
import format from 'date-fns/format';
import num2str from '../lib/num2str';

const useStylesBlock = makeStyles(theme => ({
  root: {
    padding: '0 64px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
}));

function Block({ className, ...props }) {
  const classes = useStylesBlock();

  return <Box {...props} className={clsx(className, classes.root)} />;
}

const useStylesRow = makeStyles(theme => ({
  root: {
    '& $rowTitle': {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(18),
    },

    '& $rowText': {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(16),
    },
  },
  rowTitle: {},
  rowText: {},
  row: {
    borderBottom: '1px solid rgba(235, 238, 243, 0.6)',
    paddingBottom: 8,
  },
}));

function Row({ title, value, ...props }) {
  const classes = useStylesRow();

  return (
    <Grid {...props} item md={6} xs={12} className={classes.root}>
      <Grid container className={classes.row}>
        <Grid item xs={5}>
          <Typography className={classes.rowTitle} color="primary">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.rowText}>{value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
    borderRadius: 12,
    maxWidth: 720,
  },
  content: {
    padding: '24px 0 0',

    '& $rowTitle': {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(18),
    },

    '& $rowSubTitle': {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(16),
      color: 'rgba(74, 100, 126, 0.6)',
    },

    '& $rowText': {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(16),
    },

    '& $rowTextSmall': {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  titleWrap: {
    padding: '48px 64px 32px',
    borderBottom: '4px solid #F9FAFB',

    '& $title': {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(26),
    },
  },
  title: {},
  close: {
    position: 'absolute',
    top: 24,
    right: 32,
  },
  img: {
    verticalAlign: 'middle',
    height: 64,
  },
  rowTitle: {},
  rowSubTitle: {},
  rowText: {},
  rowTextSmall: {},
  details: {
    borderTop: '1px dashed #EBEEF3',
    borderBottom: '1px dashed #EBEEF3',
    padding: '40px 64px 24px',
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      padding: '40px 16px 24px',
    },
  },
  priceWrap: {
    padding: '24px 64px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px 32px',
    },
  },
  priceBlock: {
    minHeight: 116,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      '&:first-child': {
        borderRight: '1px dashed #EBEEF3',
      },
    },

    '& $mainPrice': {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  gray: {
    color: '#4A647E',
  },
  strike: {
    position: 'relative',

    '&:before': {
      position: 'absolute',
      display: 'block',
      content: "''",
      width: 'calc(100% + 12px)',
      height: 1,
      background: '#50B848',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },

  priceValue: {
    marginLeft: 12,
  },
  mainPrice: {},
  message: {
    marginBottom: 24,

    '& $resultSubTitle': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(10),
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    '& $resultTitle': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(26),
      margin: '8px 0 16px 0',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    '& $resultText': {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(26),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  resultSubTitle: {},
  resultTitle: {},
  resultText: {},
  highlight: {
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
  },
  column: {
    top: 0,
    transform: 'translateX(0)',
    height: '100%',

    '& $subTitle': {
      textAlign: 'center',
    },

    '& $title': {
      textAlign: 'center',
    },

    '& $text': {
      textAlign: 'center',
    },

    '& $btnWrap': {
      justifyContent: 'center',
    },

    '& $textContent': {
      padding: '24px 24px 0 24px',
    },

    '& $btnContent': {
      padding: '0 24px 32px 24px',
    },
  },

  inputsWrap: {
    height: '100%',
  },
  inputs: {
    height: '100%',
  },
  marks: {
    height: 'auto',
    position: 'relative',
    padding: '8px 0',

    '&:before': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: 2,
      background: dev =>
        `url(${
          dev
            ? '/images/dot.svg'
            : 'https://order-widget.gruzovichkof.ru/images/dot.svg'
        })`,
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  pointWrap: {
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
    position: 'relative',
    paddingTop: 12,
    marginTop: -12,
  },
  point: {
    width: 6,
    height: 6,
    background: theme.palette.primary.main,
    borderRadius: '50%',
  },
}));

export default function Bill({
  status,
  onReset,
  city,
  dev,
  direction,
  calcParams,
  orderParams,
}) {
  const classes = useStyles();
  const [time, setTime] = useState(5);
  const isColumn = direction === 'column';

  function onClick() {
    onReset();
  }

  useEffect(() => {
    let timer;
    if (time > 0 && !!status) {
      timer = setTimeout(() => {
        setTime(prev => prev - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [time, status]);

  if (
    !orderParams ||
    !orderParams.order_date ||
    !orderParams.order_addresses ||
    showParams(
      calcParams.car_length,
      calcParams.car_height,
      calcParams.car_width,
    ) ||
    showLifting(calcParams.car_lifting)
  ) {
    return null;
  }

  const day = parseInt(orderParams.order_date.split('-')[2]);
  const month = parseInt(orderParams.order_date.split('-')[1]) - 1;
  const year = parseInt(orderParams.order_date.split('-')[0]);
  const hours = parseInt(orderParams.order_time.slice(0, 2));
  const minutes = parseInt(orderParams.order_time.slice(-2));

  return (
    <Dialog
      open={!!status}
      onClose={onClick}
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <div className={classes.titleWrap}>
        <Typography className={classes.title}>
          Ваш заказ на {format(new Date(year, month, day), 'dd.MM.yyyy')} г.
        </Typography>
        <IconButton className={classes.close} color="primary" onClick={onClick}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
      <DialogContent classes={{ root: classes.content }}>
        <Block
          className={clsx(classes.message, {
            [classes.column]: isColumn,
          })}
        >
          <Grid item xs={12} className={classes.textContent}>
            <Typography className={classes.resultSubTitle}>
              Ваш заказ успешно оформлен
            </Typography>
            <Typography className={classes.resultTitle}>
              Спасибо, что выбрали сервис ГрузовичкоФ.
            </Typography>
            <Typography className={classes.resultText}>
              За 15 минут до подачи автомобиля вам придёт sms-сообщение с
              информацией о водителе. Если Вы хотите внести изменения в заказ,
              позвоните по номеру{' '}
              <span className={classes.highlight}>{city.phone}</span>.
              <br />
              <Typography
                className={classes.resultText}
                component="span"
                color="primary"
              >
                <i>
                  В это непростое время, мы следим за здоровьем наших экипажей и
                  сотрудников, и постоянно проводим дезинфекцию автомобилей.
                  Желаем Вам и вашим близким хорошего настроения и крепкого
                  здоровья, не смотря ни на что!
                </i>
              </Typography>
              <br />
              Приятного переезда!
            </Typography>
          </Grid>
        </Block>
        <Block>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.rowTitle}>
                    {calcParams.car_name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.rowSubTitle}>
                    {showParams(
                      calcParams.car_length,
                      calcParams.car_height,
                      calcParams.car_width,
                    )}{' '}
                    {showLifting(calcParams.car_lifting)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={`${
                      dev
                        ? `/images/cars/${orderParams.order_type_auto}.png`
                        : `https://order-widget.gruzovichkof.ru/images/cars/${orderParams.order_type_auto}.png`
                    }`}
                    alt={calcParams.car_name}
                    className={classes.img}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography className={classes.rowTitle} color="primary">
                    Адрес
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item md={1} xs={1} className={classes.marks}>
                      <Grid
                        container
                        className={classes.inputs}
                        justify="space-between"
                        alignItems="center"
                        direction="column"
                      >
                        <Grid item className={classes.pointWrap}>
                          <div className={classes.point} />
                        </Grid>
                        <Grid item className={classes.pointWrap}>
                          <div className={classes.point} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={11} xs={11}>
                      <Grid
                        container
                        className={classes.inputs}
                        alignContent="space-between"
                        spacing={5}
                      >
                        <Grid item xs={12}>
                          <Typography className={classes.rowText}>
                            <Typography
                              className={clsx(
                                classes.gray,
                                classes.rowTextSmall,
                              )}
                              component="span"
                            >
                              Откуда:
                            </Typography>{' '}
                            {
                              orderParams.order_addresses
                                .split(';')[0]
                                .split(':')[2]
                            }
                            {orderParams.order_addresses
                              .split(';')[0]
                              .split(':')[3] &&
                              `, ${
                                orderParams.order_addresses
                                  .split(';')[0]
                                  .split(':')[3]
                              }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.rowText}>
                            <Typography
                              className={clsx(
                                classes.gray,
                                classes.rowTextSmall,
                              )}
                              component="span"
                            >
                              Куда:
                            </Typography>{' '}
                            {
                              orderParams.order_addresses
                                .split(';')[1]
                                .split(':')[2]
                            }
                            {orderParams.order_addresses
                              .split(';')[1]
                              .split(':')[3] &&
                              `, ${
                                orderParams.order_addresses
                                  .split(';')[1]
                                  .split(':')[3]
                              }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Block>
        <Block className={classes.details}>
          <Grid container spacing={4}>
            <Row title="Телефон" value={`+${orderParams.user_phone}`} />
            <Row title="Грузчики" value={orderParams.gr_cnt_porter} />
            <Row title="Подача" value={`в ${hours}:${minutes}`} />
            <Row title="Пассажиры" value={orderParams.gr_cnt_pass} />
            <Row
              title="Длит-ть"
              value={
                calcParams.json_data.car_minutes / 60 +
                ' ' +
                num2str(calcParams.json_data.car_minutes / 60, [
                  'час',
                  'часа',
                  'часов',
                ])
              }
            />
            <Row
              title="Доп. час"
              value={`${orderParams.price_add_hour_totla} р`}
            />
          </Grid>
        </Block>
        <Grid container>
          <Grid item md={6} xs={12} className={classes.priceBlock}>
            <Block className={classes.priceWrap}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={clsx(classes.rowTitle, classes.gray)}>
                    Обычная цена:{' '}
                    <Typography
                      className={clsx(
                        classes.rowTitle,
                        classes.priceValue,
                        classes.strike,
                      )}
                      component="span"
                    >
                      {Math.floor((orderParams.order_c_auto * 1.3) / 100) *
                        100 +
                        90}{' '}
                      р
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.rowTitle}>
                    Скидка:{' '}
                    <Typography
                      className={clsx(classes.rowTitle, classes.priceValue)}
                      color="primary"
                      component="span"
                    >
                      30%
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Block>
          </Grid>
          <Grid item md={6} xs={12} className={classes.priceBlock}>
            <Block className={classes.priceWrap}>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography className={classes.rowTitle}>
                    Сумма заказа со скидкой:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={clsx(
                      classes.rowTitle,
                      classes.priceValue,
                      classes.mainPrice,
                    )}
                    color="primary"
                    component="span"
                  >
                    {orderParams.order_c_auto} р
                  </Typography>
                </Grid>
              </Grid>
            </Block>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
