import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  smallSpacing: {
    width: 'calc(100% + 4px)',
    margin: -2,

    '& > div': {
      padding: 2,
    },
  },
}));

export default function GridSpacingSmall({ className, ...props }) {
  const classes = useStyles();

  return (
    <Grid
      {...props}
      container
      className={clsx(className, classes.smallSpacing)}
    />
  );
}
