import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from './ui/Button';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    border: '4px solid #F5F5F5',
    borderRadius: 20,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: -20,

    '&:hover': {
      background: '#DCF1DA',
      color: '#00652E',
    },
  },
}));

export default function ConfirmBtn(props) {
  const classes = useStyles();

  return <Button {...props} variant="outlined" className={classes.root} />;
}
