import React from 'react';
import Mask from 'react-input-mask';

export default function InputMask({ inputRef, ...props }) {
  return (
    <Mask
      {...props}
      alwaysShowMask
      mask="9 9 9 9"
      maskChar="—"
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
}
