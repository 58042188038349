import { gql } from 'apollo-boost';

export const GET_FLEET_QUERY = gql`
  query GetCarClasses($city: Int!) {
    carClasses(city: $city) {
      array {
        id
      }
      car_classes {
        id
        name
        description
        cat
        cnt_pass
        length
        height
        width
        lifting
      }
    }
  }
`;

export const GET_STREET_QUERY = gql`
  query getStreet(
    $city: Int!
    $radius: Float!
    $lng: Float!
    $lat: Float!
    $query: String!
  ) {
    searchStreet(
      city: $city
      radius: $radius
      lng: $lng
      lat: $lat
      query: $query
    ) {
      id
      name
      parent
      lat
      lng
    }
  }
`;

export const GET_BUILDING_QUERY = gql`
  query getBuilding($city: Int!, $hwid: Float!, $query: String!) {
    searchBuilding(city: $city, hwid: $hwid, query: $query) {
      house
      lat
      lng
    }
  }
`;

export const GET_OPTIONS_QUERY = gql`
  query GetOrderOptions($city: Int!) {
    orderOptions(city: $city) {
      id_option
      name
    }
  }
`;

export const GET_PRICE_QUERY = gql`
  query GetPrice(
    $city: Int!
    $loc: [String!]!
    $options: String!
    $order_date: String!
    $ordertime: String!
    $json_data: JSON!
    $discount: Int
  ) {
    getPrice(
      city: $city
      loc: $loc
      options: $options
      order_date: $order_date
      ordertime: $ordertime
      json_data: $json_data
      discount: $discount
    ) {
      prices
      control_distance
      route_summary {
        total_distance
      }
    }
  }
`;

export const SEND_CODE_QUERY = gql`
  query SendCode($city: Int!, $user_phone: String!) {
    sendCode(city: $city, user_phone: $user_phone) {
      status
      error
    }
  }
`;

export const CHECK_CODE_QUERY = gql`
  query CheckCode($user_phone: String!, $user_code: String!) {
    checkCode(user_phone: $user_phone, user_code: $user_code) {
      status
      error
    }
  }
`;

export const ADD_ORDER_QUERY = gql`
  query AddOrder(
    $city: Int!
    $discount_proc_used: Float!
    $card_name_std: Float
    $dist: Float!
    $gr_cnt_porter: Float!
    $gr_cnt_pass: Float!
    $is_now2: Int!
    $jtoken_price_gr: String!
    $order_addresses: String!
    $order_c_auto: Float!
    $order_date: String!
    $order_options: String!
    $order_time: String!
    $order_type_auto: String!
    $price_hash: String!
    $promo_discount_price: Float!
    $rand_id: Float!
    $trans_id: Float!
    $user_phone: String!
    $user_code: String!
  ) {
    addOrder(
      city: $city
      discount_proc_used: $discount_proc_used
      card_name_std: $card_name_std
      dist: $dist
      gr_cnt_porter: $gr_cnt_porter
      gr_cnt_pass: $gr_cnt_pass
      is_now2: $is_now2
      jtoken_price_gr: $jtoken_price_gr
      order_addresses: $order_addresses
      order_c_auto: $order_c_auto
      order_date: $order_date
      order_options: $order_options
      order_time: $order_time
      order_type_auto: $order_type_auto
      price_hash: $price_hash
      promo_discount_price: $promo_discount_price
      rand_id: $rand_id
      trans_id: $trans_id
      user_phone: $user_phone
      user_code: $user_code
    ) {
      status
      error
    }
  }
`;

export const BAD_ORDER_QUERY = gql`
  query badOrder($order: String!, $error: String!) {
    badOrder(order: $order, error: $error)
  }
`;

export const EMAIL_ORDER_QUERY = gql`
  query emailOrder($order: String!, $to: String!) {
    emailOrder(order: $order, to: $to)
  }
`;

export const GET_NEAREST_CARS = gql`
  query getNearestCars($city: Int!, $lat: Float!, $lng: Float!) {
    getNearestCars(city: $city, lat: $lat, lng: $lng) {
      nearest_cars {
        id_type_auto
        lat
        lng
      }
    }
  }
`;

export const GET_DELIVERY_TIME = gql`
  query getDeliveryTime($city: Int!, $loc: [String!]!) {
    getDeliveryTime(city: $city, loc: $loc) {
      obj {
        distances {
          time
        }
      }
    }
  }
`;
