import React from 'react';
import MuiClickAwayListener from '@material-ui/core/ClickAwayListener';

export default function ClickAwayListener({
  disabled,
  mouseEvent,
  touchEvent,
  ...props
}) {
  return (
    <MuiClickAwayListener
      {...props}
      mouseEvent={disabled ? false : mouseEvent}
      touchEvent={disabled ? false : touchEvent}
    />
  );
}
