export function convertParam(num) {
  let param = parseFloat(num).toFixed(2);
  if (param.toString().indexOf(0) !== -1) {
    param = parseFloat(num).toFixed(1);
    if (param.toString().indexOf(0) !== -1) {
      param = parseFloat(param).toFixed(0);
    }
  }
  return parseFloat(param);
}

export function showParams(length, height, width) {
  const lengthConvert = convertParam(length);
  const heightConvert = convertParam(height);
  const widthConvert = convertParam(width);

  const lengthString = lengthConvert !== 0 ? `${lengthConvert}м/` : '';
  const heightString = heightConvert !== 0 ? `${heightConvert}м/` : '';
  const widthString = widthConvert !== 0 ? `${widthConvert}м` : '';

  return lengthString + heightString + widthString;
}

export function showLifting(lifting) {
  const liftingValue = parseFloat(lifting);
  const liftingConvert = liftingValue * 1000;
  const liftingString = liftingValue > 0 ? `Груз ${liftingConvert}кг` : '';
  return liftingString;
}

export function heightType(car) {
  const carId = parseInt(car.id);
  const length = convertParam(car.length);
  const height = convertParam(car.height);

  switch (carId) {
    case 17:
      car.bodyType = 1;
      break;
    case 18:
      car.bodyType = 0;
      break;
    case 19:
      car.bodyType = 0;
      break;
    case 20:
      car.bodyType = 1;
      break;
    case 21:
      car.bodyType = 2;
      break;
    case 22:
      car.bodyType = 0;
      break;
    case 23:
      car.bodyType = 1;
      break;
    case 24:
      car.bodyType = 0;
      break;
    case 25:
      car.bodyType = 1;
      break;
    case 26:
      car.bodyType = 2;
      break;
    case 27:
      car.bodyType = 2;
      break;
    case 28:
      car.bodyType = 0;
      break;
    case 29:
      car.bodyType = 1;
      break;
    case 30:
      car.bodyType = 0;
      break;
    case 31:
      car.bodyType = 1;
      break;
    case 32:
      car.bodyType = 0;
      break;
    case 33:
      car.bodyType = 0;
      break;
    case 34:
      car.bodyType = 0;
      break;
    case 35:
      car.bodyType = 0;
      break;
    case 36:
      car.bodyType = 0;
      break;
    case 37:
      car.bodyType = 2;
      break;
    case 38:
      car.bodyType = 0;
      break;
    case 39:
      car.bodyType = 0;
      break;
    case 40:
      car.bodyType = 0;
      break;
    case 41:
      car.bodyType = 1;
      break;
    case 42:
      car.bodyType = 2;
      break;
    case 43:
      car.bodyType = 0;
      break;
    case 44:
      car.bodyType = 0;
      break;
    case 45:
      car.bodyType = 0;
      break;
    case 46:
      car.bodyType = 1;
      break;
    case 47:
      car.bodyType = 2;
      break;
    case 48:
      car.bodyType = 0;
      break;
    case 49:
      car.bodyType = 0;
      break;
    case 50:
      car.bodyType = 0;
      break;
    case 51:
      car.bodyType = 1;
      break;
    case 52:
      car.bodyType = 2;
      break;
    case 53:
      car.bodyType = 0;
      break;
    case 54:
      car.bodyType = 0;
      break;
    case 55:
      car.bodyType = 0;
      break;
    case 56:
      car.bodyType = 1;
      break;
    case 57:
      car.bodyType = 2;
      break;
    case 58:
      car.bodyType = 0;
      break;
    case 62:
      car.bodyType = 'any';
      break;
    case 64:
      car.bodyType = 'any';
      break;
    case 65:
      car.bodyType = 'any';
      break;
    case 86:
      car.bodyType = 1;
      break;
    case 89:
      car.bodyType = 0;
      break;
    default:
      car.bodyType = null;
      break;
  }

  if (length > 0) {
    if (length > 3) {
      car.lengthType = 0;
    } else {
      car.lengthType = 1;
    }
  } else {
    switch (carId) {
      case 62:
        car.lengthType = 'all';
        break;
      case 65:
        car.lengthType = 'all';
        break;
      default:
        car.lengthType = null;
        break;
    }
  }
  if (height > 0) {
    if (height > 1.8) {
      car.heightType = 0;
    } else {
      car.heightType = 1;
    }
  } else {
    switch (carId) {
      case 21:
        car.heightType = 'all';
        break;
      case 26:
        car.heightType = 'all';
        break;
      case 27:
        car.heightType = 'all';
        break;
      case 37:
        car.heightType = 'all';
        break;
      case 42:
        car.heightType = 'all';
        break;
      case 47:
        car.heightType = 'all';
        break;
      case 52:
        car.heightType = 'all';
        break;
      case 57:
        car.heightType = 'all';
        break;
      case 59:
        car.heightType = 'all';
        break;
      case 60:
        car.heightType = 'all';
        break;
      case 61:
        car.heightType = 'all';
        break;
      case 62:
        car.heightType = 'all';
        break;
      case 64:
        car.heightType = 'all';
        break;
      case 65:
        car.heightType = 'all';
        break;
      default:
        car.heightType = null;
        break;
    }
  }
}
