/**
 * Включаем опции по ключу enable
 *
 * @param {Option[]} options Все опции
 * @return {string} Битмаска
 */
export function encodeOptions(options) {
  if (!options || options.length === 0) {
    return false;
  }

  /**
   * Максимальный ID опции
   */
  const size = Math.max.apply(
    Math,
    options.map(option => option.id_option),
  );

  /**
   * Длина bitmap = максимальный ID опции
   */
  const bitmap = Array(size).fill(0);

  /**
   * Включаем опции, если enable === true
   */
  options.forEach(option => {
    const index = option.id_option - 1;
    if (option.enable) {
      bitmap[index] = 1;
    }
  });

  /**
   * Преобразование в строку
   */
  const result = bitmap.reverse().join('');

  return result;
}
