import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from './Button';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 24,
    border: '1px solid #F5F7FF',
    borderRadius: 5,
    position: 'relative',
  },

  indicator: {
    position: 'absolute',
    top: -1,
    left: -1,
    height: 'calc(100% + 2px)',
    transition: '.3s',
    background: '#E3EAFA',
    borderRadius: 5,
  },

  button: {
    width: '100%',
    height: '100%',
    padding: 0,
    borderRadius: 5,
    color: '#8A8AA8',

    '&:hover': {
      background: 'none',
    },
  },
  active: {
    color: '#242D39',
  },
  text: {
    fontSize: theme.typography.pxToRem(10),
    textTransform: 'uppercase',
    fontWeight: 600,
  },
}));

function checkTranslate(i, max) {
  switch (i) {
    case 0:
      return 0;
    case max:
      return 100 * max;
    default:
      return 100 * i;
  }
}

const SwitchTabs = ({ className, variants, onClick }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const max = variants.length - 1;
  const translateX = checkTranslate(tab, max);

  function onChange(value, index) {
    onClick(value);
    setTab(index);
  }

  return (
    <Grid container className={clsx(className, classes.root)}>
      <div
        className={classes.indicator}
        style={{
          width: `calc((100% + 2px) / ${max + 1})`,
          transform: `translateX(${translateX}%)`,
        }}
      />
      {variants.map(({ key, value }, index) => (
        <Grid item className={classes.item} xs key={key}>
          <Button
            disableRipple
            className={clsx(classes.button, {
              [classes.active]: tab === index,
            })}
            onClick={() => onChange(key, index)}
          >
            <Typography className={classes.text}>{value}</Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default SwitchTabs;
