import React, { useState } from 'react';
import addMinutes from 'date-fns/addMinutes';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '../ui/Button';

const useStylesItem = makeStyles((theme) => ({
  btn: {
    background: '#FFFFFF',
    borderRadius: 5,
    border: '1px solid rgba(74, 100, 126, 0.1)',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'normal',
    height: 30,
  },
  active: {
    background: '#DCF1DA',
    borderColor: '#DCF1DA',
    color: '#00652E',

    '&:after': {
      position: 'absolute',
      content: '""',
      left: '50%',
      top: 0,
      transform: 'translate(-50%, -5px)',
      background: '#DCF1DA',
      border: '2px solid #FFFFFF',
      borderRadius: 4,
      width: 20,
      height: 10,
    },
  },
}));

function Item({ active, ...props }) {
  const classes = useStylesItem();
  return (
    <Grid item>
      <Button
        {...props}
        className={clsx(classes.btn, {
          [classes.active]: active,
        })}
      />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#8A8AA8',
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 'normal',
    textTransform: 'uppercase',
  },

  times: {
    paddingTop: 8,
  },
}));

export default function AfterTime({ setDate }) {
  const classes = useStyles();
  const date = new Date();
  const [minutes, setMinutes] = useState(0);

  function onClick(minutes) {
    const newDate = addMinutes(date, minutes);
    setMinutes(minutes);
    setDate(newDate);
  }

  return (
    <>
      <Typography className={classes.title} align="center">
        Срочная подача через
      </Typography>
      <Grid container justify="center" className={classes.times} spacing={1}>
        <Item onClick={() => onClick(15)} active={minutes === 15}>
          15 мин
        </Item>
        <Item onClick={() => onClick(30)} active={minutes === 30}>
          30 мин
        </Item>
        <Item onClick={() => onClick(60)} active={minutes === 60}>
          1 час
        </Item>
        <Item onClick={() => onClick(120)} active={minutes === 120}>
          2 часа
        </Item>
      </Grid>
    </>
  );
}
