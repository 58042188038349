import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 66,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '8px 0',
    scrollSnapAlign: 'center',
  },
  item: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 'normal',
    color: 'rgba(74, 100, 126, 0.3)',
    [theme.breakpoints.down('sm')]: {
      transition: '.3s',
    },
  },
  activeRoot: {
    alignItems: 'center',
    padding: 0,

    '& + $root': {
      alignItems: 'flex-start',
    },
  },
  activeItem: {
    fontSize: theme.typography.pxToRem(36),
    color: '#242D39',
  },
}));

export default function TimeItem({ children, active, onClick, ...props }) {
  const classes = useStyles();
  return (
    <Grid
      {...props}
      item
      xs={12}
      className={clsx(classes.root, {
        [classes.activeRoot]: active,
      })}
    >
      <ButtonBase onClick={!active && onClick} disableRipple disabled={active}>
        <Typography
          className={clsx(classes.item, {
            [classes.activeItem]: active,
          })}
        >
          {children}
        </Typography>
      </ButtonBase>
    </Grid>
  );
}
