import { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { encodeOptions } from '../lib/bitmask.mjs';
import { useQuery } from '@apollo/react-hooks';
import { GET_OPTIONS_QUERY } from '../lib/query';

// const useStyles = makeStyles(theme => ({}));

export default function CalcType({ onLoad, enableOptions, city, setStatus }) {
  // const classes = useStyles();
  const [encodeEnableOptions, setEncodeEnableOptions] = useState(null);
  const options = [];

  const { loading, error, data } = useQuery(GET_OPTIONS_QUERY, {
    variables: {
      city,
    },
  });

  useEffect(() => {
    if (options.length > 0) {
      setEncodeEnableOptions(encodeOptions(options));
    }
  }, [options]);

  useEffect(() => {
    if (!loading && encodeEnableOptions !== null) {
      onLoad('optionsLoaded');
      enableOptions({
        type: 'add',
        payload: {
          options: encodeEnableOptions,
        },
      });
    }
  }, [loading, onLoad, enableOptions, encodeEnableOptions]);

  if (loading) return null;
  if (error) {
    setStatus('faildLoad');
    return null;
  }

  const { orderOptions } = data;

  orderOptions.forEach((option) => {
    option.enable = false;
    options.push(option);
  });

  return null;
}
