import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
// import Info from '../icons/Info';

const useStyles = makeStyles(theme => ({
  rippleVisible: {
    backgroundColor: '#DCF1DA',
  },

  promo: {
    borderRadius: '0px 0px 10px 10px',
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    padding: '0 8px 8px 8px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 8px 20px 8px',
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 30,
      background: theme.palette.primary.main,
      borderRadius: '0px 0px 10px 10px',
      left: 0,
      bottom: 0,
      zIndex: -1,
      [theme.breakpoints.down('sm')]: {
        height: 44,
      },
    },
  },
  promoImgWrap: {
    // display: 'flex',
  },
  promoImg: {
    width: '100%',
    verticalAlign: 'middle',
  },
  promoTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: '#FFFFFF',
    fontWeight: 'normal',
    marginBottom: -2,
  },
  promoMessage: {
    background: theme.palette.primary.main,
    border: '3px solid #FFFFFF',
    borderRadius: 8,
    height: 36,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  promoMessageText: {
    fontSize: theme.typography.pxToRem(8),
    color: '#FFFFFF',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  promoMessageValue: {
    fontSize: theme.typography.pxToRem(18),
    color: '#FFFFFF',
    fontWeight: 600,
    marginTop: -4,
    marginBottom: -4,
    lineHeight: '22px',
  },
}));

export default function Promo({ onClose, fleet, dev }) {
  const classes = useStyles();

  const promoCar = fleet.find(car => car.id === '38');

  return (
    <ButtonBase
      onClick={() => onClose(promoCar)}
      TouchRippleProps={{
        classes: {
          child: classes.rippleVisible,
        },
      }}
      className={classes.promo}
    >
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={3}>
          <div className={classes.promoMessage}>
            <Typography className={classes.promoMessageText}>
              выгоднее на
            </Typography>
            <Typography className={classes.promoMessageValue}>20%</Typography>
          </div>
        </Grid>
        <Grid item xs={3} className={classes.promoImgWrap}>
          <img
            src={`${
              dev
                ? `/images/cars/${promoCar.id}.png`
                : `https://order-widget.gruzovichkof.ru/images/cars/${promoCar.id}.png`
            }`}
            alt={promoCar.name}
            className={classes.promoImg}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography className={classes.promoTitle}>
            {promoCar.name}
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </ButtonBase>
  );
}
