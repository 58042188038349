import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Block from '../Block';
import Title from '../Title';
import Cnt from '../Cnt';

const useStyles = makeStyles(theme => ({
  wrap: {
    minHeight: 81,
  },
  content: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  root: {
    height: 'calc(100% - 20px)',
  },
}));

export default function Duration(props) {
  const classes = useStyles();

  return (
    <Block
      className={classes.wrap}
      borderNone
      alignItems="stretch"
      alignContent="stretch"
    >
      <Grid item xs={12}>
        <div className={classes.content}>
          <Title>Длительность</Title>
          <Grid container alignItems="center" className={classes.root}>
            <Cnt {...props} />
          </Grid>
        </div>
      </Grid>
    </Block>
  );
}
