import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: 4,
    background: '#FFFFFF',
    border: `1px solid ${theme.palette.primary.extraLight}`,
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
  },
}));

export default function CntButton({ type, ...props }) {
  const classes = useStyles();

  return (
    <IconButton {...props} color="primary" className={classes.iconButton}>
      {type === 'plus' && <AddRoundedIcon className={classes.icon} />}
      {type === 'minus' && <RemoveRoundedIcon className={classes.icon} />}
    </IconButton>
  );
}
