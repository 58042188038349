import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    borderRadius: '0px 0px 22px 22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    margin: '0 auto',

    padding: '0 16px',
    minWidth: 128,
    maxWidth: 'fit-content',
    width: '100%',
  },
  title: {
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 600,
  },
  error: {
    background: '#FE5454',
    '& $title': {
      color: '#FFFFFF',
    },
  },
}));

export default function Title({ className, error, ...props }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.error]: error,
      })}
    >
      <Typography {...props} className={classes.title} />
    </div>
  );
}
