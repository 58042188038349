import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '../ui/Button';
import Scroll from '../ui/Scroll';

const useStyles = makeStyles(theme => ({
  root: {
    visibility: 'hidden',
    opacity: 0,
    transition: '.3s',

    position: 'absolute',
    top: 'calc(100% - 14px)',
    left: 2,
    width: 'calc(100% - 4px)',
    boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.05)',
    borderRadius: '0 0 10px 10px',
    paddingTop: 14,

    zIndex: 0,
    background: '#ffffff',
  },

  open: {
    visibility: 'visible',
    opacity: 1,
  },

  list: {
    maxHeight: 256,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 348,
    },

    padding: 0,
    margin: 0,
  },

  item: {
    listStyle: 'none',
  },

  name: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(15),
  },
  parentName: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(11),
    color: '#D0C9D6',
    transition: '.3s',
  },

  scroll: {
    '& .rcs-custom-scroll': {
      height: '100%',
    },
    '& .rcs-custom-scroll .rcs-outer-container': {
      height: '100%',
    },
    '& .rcs-custom-scroll .rcs-inner-container': {
      height: '100%',
    },
  },

  btn: {
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: '8px 36px',

    '&:hover': {
      backgroundColor: '#50b8484d',

      '& $parentName': {
        color: '#242d39',
      },
    },
  },
  btnLabel: {
    flexDirection: 'column',
  },
}));

export default function Results({
  open,
  results,
  city,
  activeField,
  onClick,
  locField,
  ...props
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.open]: open,
      })}
    >
      <Scroll className={classes.scroll} allowOuterScroll={true}>
        <ul
          className={clsx(classes.list, {
            [classes.open]: open,
          })}
        >
          {results &&
            results[activeField] &&
            results[activeField].map((item, index) => (
              <li key={index} className={classes.item}>
                <Button
                  {...props}
                  fullWidth
                  className={classes.btn}
                  onClick={() =>
                    onClick(
                      locField,
                      item.name ? item.name : item.house,
                      activeField,
                      {
                        id: item.id,
                        loc: `${item.lat},${item.lng}`,
                        lat: item.lat,
                        lng: item.lng,
                        name: item.name ? item.name : item.house,
                        city,
                      },
                    )
                  }
                  classes={{ label: classes.btnLabel }}
                >
                  <Typography className={classes.name}>
                    {item.name ? item.name : item.house}
                  </Typography>
                  {item.parent && (
                    <Typography className={classes.parentName}>
                      {item.parent}
                    </Typography>
                  )}
                </Button>
              </li>
            ))}
        </ul>
      </Scroll>
    </div>
  );
}
