import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../ui/TextField';

const useStyles = makeStyles((theme) => ({
  active: {
    textTransform: 'uppercase',
    fontWeight: 'normal',
    fontSize: '0.625rem',
    transform: 'scale(1)',
  },
}));

export default function Input({ InputProps, InputLabelProps, ...props }) {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      fullWidth
      type="text"
      InputProps={{
        ...InputProps,
        disableUnderline: true,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        classes: { shrink: classes.active },
      }}
      autoComplete="off"
    />
  );
}
