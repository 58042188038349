import React, { useState, useEffect } from 'react';
import md5 from 'md5';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../ui/TextField';
import CodeInput from '../ui/CodeInput';
import Title from '../Title';
import Back from '../icons/Back';
import { useQuery } from '@apollo/react-hooks';
import { CHECK_CODE_QUERY } from '../../lib/query';
import clsx from 'clsx';
import Block from '../Block';

const errorMessage = error => {
  switch (error) {
    case 'banned':
      return 'Заблокировано';
    case 'incorrect_user_code':
      return 'Неверный код';
    case 'required':
      return 'Обязательно';
    default:
      return 'Ошибка';
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,

    transition: '.3s',
    opacity: 0,
    visibility: 'hidden',
    minHeight: 81,
  },
  open: {
    opacity: 1,
    visibility: 'visible',
    animationName: '$eyeTracking',
    animationDuration: '1.5s',
    animationDelay: '.5s',
  },
  contentWrap: {
    padding: '0 16px',
    height: 'calc(100% - 20px)',
    margin: 0,
    width: '100%',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  btn: {
    height: 28,
    width: 28,
    borderRadius: '50%',
    padding: 0,
    background: '#FFFFFF',
    border: '1px solid #50B848',
    transition: '.3s',

    position: 'absolute',
    right: 8,
    top: '50%',
    transform: 'translateY(-50%)',

    zIndex: 1,

    '&:hover': {
      background: '#DCF1DA',
      '& $icon': {
        color: '#00652E',
      },
    },
  },
  input: {
    textAlign: 'center',
    padding: 0,
  },
  icon: {
    width: 11,
  },
  disabled: {
    background: '#F7F7F9 !important',
    opacity: 0.5,
  },
  '@keyframes eyeTracking': {
    '0%': {
      borderColor: '#F7F7F9',
    },
    '25%': {
      borderColor: theme.palette.primary.main,
    },
    '50%': {
      borderColor: '#F7F7F9',
    },
    '75%': {
      borderColor: theme.palette.primary.main,
    },
    '100%': {
      borderColor: '#F7F7F9',
    },
  },
}));

export default function EnterCode({
  phone,
  codeSent,
  setCodeSent,
  setPhoneFull,
  setPhone,
  setUserCode,
  error,
  setError,
}) {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [codeFull, setCodeFull] = useState(false);
  const [querySkip, setQuerySkip] = useState(true);
  const [data, setData] = useState(null);

  function checkError(error) {
    if (error && error.checkCode) {
      return true;
    }
    return false;
  }

  const { loading } = useQuery(CHECK_CODE_QUERY, {
    skip: querySkip,
    fetchPolicy: 'network-only',
    variables: {
      user_phone: phone.replace(/\D+/g, ''),
      user_code: code.replace(/\D+/g, ''),
    },
    onCompleted: data => {
      setData(data);
      setQuerySkip(true);
    },
  });

  useEffect(() => {
    const length = code.replace(/\D+/g, '').length;
    if (!codeFull && length === 4) {
      setCodeFull(true);
      setQuerySkip(false);
    }
    return () => {
      if (data) {
        setQuerySkip(true);
      }
    };
  }, [code, codeFull, data]);

  useEffect(() => {
    if (data) {
      switch (data.checkCode.status) {
        case '1':
          const user_code = md5(code.replace(/\D+/g, '') + 'hef45Gh87jf4E');
          setUserCode({ user_code });
          break;
        case '-1':
          setError({ ...error, checkCode: data.checkCode.error[0] });
          break;
        default:
          break;
      }
    }
    return () => {
      setQuerySkip(true);
      setData(null);
    };
  }, [data, setError, setUserCode, code, phone, error]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (checkError(error)) {
        setError({ ...error, checkCode: null });
        setCode('');
        setCodeFull(false);
      }
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [setCodeSent, setPhone, setPhoneFull, error, setError]);

  function onChange(e) {
    setCode(e.target.value);
  }

  function onClick() {
    if (codeSent) {
      setCodeSent(false);
      setPhoneFull(false);
      setPhone('');
    }
  }

  return (
    <Block
      alignItems="stretch"
      alignContent="stretch"
      error={checkError(error)}
      className={clsx(classes.root, {
        [classes.open]: codeSent,
      })}
      borderNone
    >
      <Grid item xs={12}>
        <Title error={checkError(error)}>
          {checkError(error) ? errorMessage(error.checkCode) : 'Введите код'}
        </Title>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.contentWrap}
        >
          <IconButton
            color="primary"
            variant="outlined"
            className={classes.btn}
            classes={{
              disabled: classes.disabled,
            }}
            disabled={loading || codeFull}
            onClick={onClick}
          >
            <Back className={classes.icon} color="primary" />
          </IconButton>
          <Grid item xs={12}>
            <div className={classes.content}>
              <TextField
                InputProps={{
                  inputComponent: CodeInput,
                  disableUnderline: true,
                  readOnly: codeFull,
                  classes: {
                    input: classes.input,
                  },
                }}
                name="code"
                type="tel"
                pattern="[0-9]*"
                value={code}
                onChange={onChange}
                autoComplete="off"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Block>
  );
}
