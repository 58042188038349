import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../ui/Button';
import ClearRounded from '@material-ui/icons/ClearRounded';
import Info from '../icons/Info';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 6,
    left: -2,
    width: 'calc(100% + 4px)',
    height: 'calc(100% - 4px)',
    background: '#FFFFFF',
    boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.05)',
    borderRadius: 10,
    padding: '30px 8px 8px 8px',

    transition: '.3s',

    opacity: 0,
    visibility: 'hidden',
  },
  open: {
    opacity: 1,
    visibility: 'visible',
  },
  action: {
    flexGrow: 0,
    maxWidth: 36,
    flexBasis: 36,
  },
  content: {
    flexGrow: 0,
    maxWidth: 'calc(100% - 36px)',
    flexBasis: 'calc(100% - 36px)',
  },
  close: {
    minWidth: 'auto',
    padding: 0,
    width: '100%',
    height: 75,

    '& $iconClose': {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  iconClose: {},
  infoWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    margin: 'auto',
    display: 'flex',
    marginTop: 6,
  },
  container: {
    height: '100%',
  },
  text: {
    paddingLeft: 24,

    '& $title': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(20),
    },

    '& $description': {
      fontSize: theme.typography.pxToRem(18),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  },
  title: {
    marginBottom: 16,
  },
  description: {},
}));

export default function Hint({ open, onClose, name, description }) {
  const classes = useStyles();
  const text = description.slice(0, 80);

  return (
    <div
      className={clsx(classes.root, {
        [classes.open]: open,
      })}
    >
      <Grid container className={classes.container}>
        <Grid item className={classes.action}>
          <Grid
            container
            className={classes.container}
            alignContent="space-between"
          >
            <Grid item xs={12} className={classes.infoWrap}>
              <Info color="primary" className={classes.info} />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                className={classes.close}
                onClick={onClose}
              >
                <ClearRounded className={classes.iconClose} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.content}>
          <Grid container className={classes.text}>
            <Grid item xs={12}>
              <Typography className={classes.title}>{name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.description}>{text}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
