import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { makeStyles } from '@material-ui/core/styles';
import { showParams, showLifting } from '../../lib/convert';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 12px',
    border: `1px solid ${theme.palette.primary.extraLight}`,
    borderRadius: 5,
    height: 72,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    width: '100%',
    transition: '.3s',

    '&:hover': {
      background: '#FFFFFF',
      borderColor: theme.palette.primary.main,
    },
  },
  title: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(16),
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    marginTop: 8,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  imgWrap: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: '100%',
  },

  arrowWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  rippleVisible: {
    backgroundColor: '#DCF1DA',
  },
}));

export default function Select({ car = {}, dev, ...props }) {
  const classes = useStyles();

  return (
    <ButtonBase
      {...props}
      TouchRippleProps={{
        classes: {
          child: classes.rippleVisible,
        },
      }}
      className={classes.root}
    >
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <div className={classes.text}>
            <Typography variant="caption" className={classes.title}>
              {car.name}
            </Typography>
            {showParams(car.length, car.height, car.width) &&
              showLifting(car.lifting) && (
                <Typography
                  variant="textButtonUltraSmall"
                  className={classes.subtitle}
                >
                  {showParams(car.length, car.height, car.width)}{' '}
                  {showLifting(car.lifting)}
                </Typography>
              )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.imgWrap}>
            <img
              src={`${
                dev
                  ? `/images/cars/${car.id}.png`
                  : `https://order-widget.gruzovichkof.ru/images/cars/${car.id}.png`
              }`}
              alt={car.name}
              className={classes.img}
            />
          </div>
        </Grid>
        <Grid item xs={1} className={classes.arrowWrap}>
          <ArrowDropDownRoundedIcon color="primary" fontSize="large" />
        </Grid>
      </Grid>
    </ButtonBase>
  );
}
