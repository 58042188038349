import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CustomScroll from 'react-custom-scroll';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .rcs-custom-scroll': {
      minHeight: 0,
      minWidth: 0,
    },
    '& .rcs-custom-scroll .rcs-outer-container': {
      overflow: 'hidden',
    },
    '& .rcs-custom-scroll .rcs-outer-container .rcs-positioning': {
      position: 'relative',
    },
    '& .rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar': {
      opacity: 1,
      transitionDuration: '0.2s',
    },
    '& .rcs-custom-scroll .rcs-inner-container': {
      overflowX: 'hidden',
      overflowY: 'scroll',
      WebkitOverflowScrolling: 'touch',
    },
    '& .rcs-custom-scroll .rcs-inner-container:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      height: 0,
      backgroundImage:
        'linear-gradient(to bottom,rgba(0, 0, 0, 0.2) 0%,rgba(0, 0, 0, 0.05) 60%,rgba(0, 0, 0, 0) 100%)',
      pointerEvents: 'none',
      transition: 'height 0.1s ease-in',
      willChange: 'height',
    },
    '& .rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after': {
      height: 5,
      transition: 'height 0.15s ease-out',
    },
    '& .rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container': {
      userSelect: 'none',
    },
    '& .rcs-custom-scroll .rcs-custom-scrollbar': {
      position: 'absolute',
      height: '100%',
      width: 3,
      right: 3,
      opacity: 0,
      zIndex: 1,
      transition: 'opacity 0.4s ease-out',
      padding: '6px 0',
      boxSizing: 'border-box',
      willChange: 'opacity',
      pointerEvents: 'none',
    },
    '& .rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl': {
      right: 'auto',
      left: 3,
    },
    '& .rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar': {
      opacity: 1,
    },
    '& .rcs-custom-scroll .rcs-custom-scroll-handle': {
      position: 'absolute',
      width: '100%',
      top: 0,
    },
    '& .rcs-custom-scroll .rcs-inner-handle': {
      height: 'calc(100% - 12px)',
      marginTop: 6,
      backgroundColor: 'rgba(80, 184, 72, 0.2)',
      borderRadius: 2,
    },
  },
}));

export default function Scroll({ className, ...props }) {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.root)}>
      <CustomScroll {...props} allowOuterScroll={true} />
    </div>
  );
}
