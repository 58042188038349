export const minutes = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
];

export const hours = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
];

export const duration = [60, 120, 180, 240, 300, 360, 420, 480];

export function checkTime(type, today, date, time) {
  const currentDate = new Date();
  const currentHours = parseInt(currentDate.getHours());
  const currentMinutes = parseInt(currentDate.getMinutes());
  const checkedHours = parseInt(date.getHours());

  if (today) {
    switch (type) {
      case 'hours':
        if (time < currentHours) {
          return false;
        }
        return true;
      case 'minutes':
        if (time < currentMinutes + 15 && currentHours === checkedHours) {
          return false;
        }
        return true;
      default:
        return false;
    }
  }
  return true;
}
