import React from 'react';
import format from 'date-fns/format';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    transition: '.3s',
    opacity: 0,
    visibility: 'hidden',
    paddingBottom: 8,

    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  open: {
    opacity: 1,
    visibility: 'visible',
  },
  row: {
    width: 48,
  },
  divider: {
    fontSize: theme.typography.pxToRem(24),
    marginTop: -4,
  },
  item: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(36),
    color: '#242D39',
  },
}));

export default function TimePreview({ date, open, ...props }) {
  const classes = useStyles();
  const hours = format(date, 'HH');
  const minutes = format(date, 'mm');

  return (
    <ButtonBase
      {...props}
      className={clsx(classes.root, {
        [classes.open]: open,
      })}
    >
      <Grid container alignItems="center" justify="center">
        <Grid item className={classes.row}>
          <Typography className={classes.item}>{hours}</Typography>
        </Grid>
        <Grid item className={classes.row}>
          <Typography className={classes.divider} align="center">
            :
          </Typography>
        </Grid>
        <Grid item className={classes.row}>
          <Typography className={classes.item}>{minutes}</Typography>
        </Grid>
      </Grid>
    </ButtonBase>
  );
}
