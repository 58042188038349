import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SwitchTabs from '../ui/SwitchTabs';
import GridSpacingSmall from '../GridSpacingSmall';

const HEIGHT_PARAMS = [
  {
    key: 0,
    value: 'Высокий',
  },
  {
    key: 1,
    value: 'Низкий',
  },
];

const LENGTH_PARAMS = [
  {
    key: 0,
    value: 'Длинный',
  },
  {
    key: 1,
    value: 'Короткий',
  },
];

const TYPE_PARAMS = [
  {
    key: 1,
    value: 'Тент',
  },
  {
    key: 0,
    value: 'Жесткий',
  },
  {
    key: 2,
    value: 'Открытый',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 8,
  },
}));

export default function Params({ setHeight, setLength, setType }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridSpacingSmall>
        <Grid item xs={12}>
          <SwitchTabs variants={HEIGHT_PARAMS} onClick={setHeight} />
        </Grid>
        <Grid item xs={12}>
          <SwitchTabs variants={LENGTH_PARAMS} onClick={setLength} />
        </Grid>
        <Grid item xs={12}>
          <SwitchTabs variants={TYPE_PARAMS} onClick={setType} />
        </Grid>
      </GridSpacingSmall>
    </div>
  );
}
