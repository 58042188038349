import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  default: {
    verticalAlign: 'middle',
    fill: 'currentColor',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  light: {
    color: '#8A8AA8',
  },
}));

export default ({ className, color }) => {
  const classes = useStyles();
  return (
    <svg
      className={clsx(classes.default, className, {
        [classes.primary]: color === 'primary',
        [classes.light]: !color || color === 'light',
      })}
      width="17"
      height="17"
      viewBox="0 0 17 17"
    >
      <path d="M5.13511 7.08306H0.531556C0.238338 7.08306 0.000366211 6.84509 0.000366211 6.55187V1.94818C0.000366211 1.65496 0.238338 1.41699 0.531556 1.41699C0.824774 1.41699 1.06275 1.65496 1.06275 1.94818V6.02068H5.13511C5.42833 6.02068 5.6663 6.25865 5.6663 6.55187C5.6663 6.84509 5.42833 7.08306 5.13511 7.08306Z" />
      <path d="M8.49938 16.9981C6.22587 16.9981 4.09191 16.1128 2.49263 14.5044C2.28514 14.2961 2.28656 13.9597 2.49406 13.7528C2.70156 13.5461 3.03796 13.5468 3.24545 13.7543C4.64501 15.1616 6.51053 15.9357 8.49938 15.9357C12.6001 15.9357 15.936 12.5998 15.936 8.49903C15.936 4.39827 12.6001 1.06238 8.49938 1.06238C5.00204 1.06238 1.86794 3.42511 1.04704 6.68176C0.974801 6.96642 0.681583 7.13851 0.401853 7.06627C0.117194 6.99469 -0.0548977 6.70575 0.0159103 6.42109C0.954959 2.70056 4.52246 0 8.49938 0C13.1858 0 16.9984 3.81261 16.9984 8.49903C16.9984 13.1856 13.1858 16.9981 8.49938 16.9981Z" />
    </svg>
  );
};
