import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#ffffff',
    visibility: 'hidden',
    opacity: 0,
    transition: '.3s',
    zIndex: 9,
  },
  open: {
    visibility: 'visible',
    opacity: 1,
  },
  preloader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    bottom: 'auto',
    right: 'auto',
    transform: 'translateX(-50%) translateY(-50%)',
    textAlign: 'center',
  },
  logoWrap: {
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 60,
  },
  spiner: {
    display: 'block',
    position: 'relative',
    left: '50%',
    top: '50%',
    width: 150,
    height: 150,
    margin: '-75px 0 0 -75px',
    borderRadius: '50%',
    border: '3px solid transparent',
    borderTopColor: theme.palette.primary.main,
    animation: '$spin 2s linear infinite',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 5,
      left: 5,
      right: 5,
      bottom: 5,
      borderRadius: '50%',
      border: '3px solid transparent',
      borderTopColor: '#FBDB44',
      animation: '$spin 3s linear infinite',
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 15,
      left: 15,
      right: 15,
      bottom: 15,
      borderRadius: '50%',
      border: '3px solid transparent',
      borderTopColor: '#DCF1DA',
      animation: '$spin 1.5s linear infinite',
    },
  },
  text: {
    fontSize: 18,
    color: '#252631',
    margin: 0,
    marginTop: 46,
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

export default function Loading({ open, dev }) {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={clsx(classes.root, {
        [classes.open]: open,
      })}
    >
      <Grid container className={classes.preloader}>
        <Grid item xs={12} className={classes.logoWrap}>
          <div className={classes.spiner} />
          <img
            src={`${
              dev
                ? '/images/preloader.svg'
                : 'https://order-widget.gruzovichkof.ru/images/preloader.svg'
            }`}
            alt="Грузовичкоф"
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={12} component="p" className={classes.text}>
          Заправляем авто...
        </Grid>
      </Grid>
    </Grid>
  );
}
