import { createMuiTheme } from '@material-ui/core/styles';

const colors = {
  white: '#FFFFFF',
  yellow: 'rgb(255, 212, 0)',
  yellowHoverLight: 'rgba(255, 212, 0, 0.03)',
  yellowActive: 'rgba(255, 212, 0, 0.5)',

  extraLightGreen: '#DCF1DA',
  lightGreen: '#8DCF88',
  darkGreen: '#00652E',
  green: '#50B848',
  greenActive: 'rgb(80, 184, 72, 0.5)',

  darkGrey: 'rgb(36, 45, 57)',
};

const shadows = {
  primary: '0px 7px 20px rgba(102, 194, 95, 0.6)',
  secondary: '0px 7px 20px rgba(238, 202, 22, 0.4)',
};

let muiTheme = createMuiTheme({
  // spacing: 4,
  typography: {
    fontFamily: '"Proxima Nova", Arial, Helvetica, sans-serif',
  },
  palette: {
    background: {
      default: colors.lightGrey,
    },
    primary: {
      extraLight: colors.extraLightGreen,
      light: colors.lightGreen,
      main: colors.green,
      dark: colors.darkGreen,
      contrastText: colors.white,

      shadow: shadows.primary,
      ripple: colors.greenActive,
    },
    secondary: {
      light: colors.lightYellow,
      main: colors.yellow,
      dark: colors.darkYellow,
      contrastText: colors.white,

      shadow: shadows.secondary,
      backgroundLight: colors.yellowHoverLight,
      ripple: colors.yellowActive,
    },
    text: {
      primary: colors.darkGrey,
      secondary: colors.darkGrey,
    },
  },
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        minHeight: 272,
        padding: '0 16px',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        width: 30,
        height: 30,
        backgroundColor: '#F9FAFB',
        // boxShadow: '0px 7px 20px rgba(0, 0, 0, 0.07)',
        borderRadius: 10,
        padding: 0,
        // color: '#ECEBED',
        transition: '.3s',

        '&:hover': {
          color: '#3F3356',
          // backgroundColor: 'transparent',
        },

        '&.Mui-disabled': {
          color: '#ECEBED',
        },
      },
      switchHeader: {
        height: 40,
      },
      daysHeader: {
        maxHeight: 'none',
        paddingBottom: 4,
        borderBottom: '1px dashed #ECEBED',
      },
      dayLabel: {
        width: 32,
        margin: '0 4px',
        color: '#D0C9D6',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: 12,
      },
    },
    MuiPickersDay: {
      day: {
        width: 32,
        height: 32,
        margin: '0 4px',

        '&:hover': {
          backgroundColor: '#CBEAC9',
          opacity: 1,
          color: '#00652E !important',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        /** Hide JivoSite button on mobile */
        'body > jdiv > jdiv > jdiv:nth-child(1) > jdiv > jdiv.button_1O': {
          display: 'none',
        },
      },
    },
  },
});

let theme = {
  ...muiTheme,
  colors,
};

const typographyVariants = {
  h1: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(38),
    lineHeight: 1,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(30),
      lineHeight: 1.266,
    },
  },
  h2: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(34),
    lineHeight: 1.2,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: 1.3,
    },
  },
  h3: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(30),
    lineHeight: 1.2,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(22),
      lineHeight: 1.454,
    },
  },
  h4: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(26),
    lineHeight: 1.2,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.4,
    },
  },
  h5: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.2,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.4,
    },
  },
  h6: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(13),
    textTransform: 'uppercase',
    letterSpacing: '0.03em',
    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.2,
    },
  },

  subtitle1: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(22),
    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.555,
    },
  },

  body1: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },

  body2: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.428,
    },
  },

  caption: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.333,
    },
  },

  menuOne: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      fontWeight: 600,
      // fontSize: theme.typography.pxToRem(16),
      // lineHeight: 1.4,
    },
  },
  menuTwo: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      // fontSize: theme.typography.pxToRem(16),
      // lineHieght: 1.4,
      fontWeight: 600,
    },
  },

  button: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1,
  },

  buttonSmall: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },

  promoButton: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(36),
      lineHeight: 0.777,
    },
  },

  textButtonSmall: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.142,

    [theme.breakpoints.down('sm')]: {
      lineHeight: 1,
    },
  },

  textButtonUltraSmall: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(10),
    lineHeight: 1,
  },

  price: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(22),
    lineHeight: 1.454,

    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.3,
    },
  },

  phone: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.3,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },

  phoneCode: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.3,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.3,
    },
  },

  descriptionInfo: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    color: '#50B848',

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(10),
    },
  },

  city: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
  },

  news: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.1875,
  },
};

theme.overrides.MuiTypography = {};
Object.keys(typographyVariants).forEach(variant => {
  theme.typography[variant] = {
    ...typographyVariants[variant],
    fontFamily: theme.typography.fontFamily,
  };

  theme.overrides.MuiCssBaseline['@global'] = {
    ...theme.overrides.MuiCssBaseline['@global'],
    [`.MuiTypography-${variant}`]: theme.typography[variant],
  };

  /**
   * форсируем применение стилей через специфичность CSS
   * @example .MuiTypography-root .MuiTypography-textButtonSmall
   */
  theme.overrides.MuiTypography['root'] = {
    ...theme.overrides.MuiTypography['root'],
    [`& ${variant}`]: theme.typography[variant],
  };

  /**
   * @example .MuiTypography-buttonSmall
   */
  theme.overrides.MuiTypography[variant] = theme.typography[variant];
});

export { shadows, colors };

export default theme;
