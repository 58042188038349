import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Scroll from '../ui/Scroll';
import Params from './Params';
import ClickAwayListener from '../ClickAwayListener';
// import Info from '../icons/Info';
import GridSpacingSmall from '../GridSpacingSmall';
import Promo from './Promo';
import Item from './Item';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    background: '#FFFFFF',
    boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.05)',
    borderRadius: 10,
    opacity: 0,
    visibility: 'hidden',
    transition: '.3s',

    zIndex: 2,

    [theme.breakpoints.down('sm')]: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '100%',
    },

    [theme.breakpoints.up('sm')]: {
      top: -87,
    },
  },
  open: {
    opacity: 1,
    visibility: 'visible',
  },
  scroll: {
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 96px)',
    },
  },
  fleetWrap: {
    padding: '0 16px 8px 8px',
    [theme.breakpoints.up('sm')]: {
      height: 334,
    },
  },
  fleet: {
    paddingBottom: 44,
  },

  gradient: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 44,
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0) 6.67%, #FFFFFF 100%)',
    borderRadius: 10,
    zIndex: 1,
  },
}));

export default function FleetList({ open, onClose, fleet, onClickAway, dev }) {
  const classes = useStyles();

  const [load, setLoad] = useState(false);
  const [height, setHeight] = useState(0);
  const [length, setLength] = useState(0);
  const [type, setType] = useState(1);

  useEffect(() => {
    if (open) {
      setLoad(true);
    }
  }, [open]);

  function filterType(item) {
    if (type === 2) {
      if (item.bodyType === type || item.bodyType === null) {
        return true;
      }
    } else {
      if (
        item.bodyType === type ||
        item.bodyType === 'any' ||
        item.bodyType === null
      ) {
        return true;
      }
    }
  }

  const filterFleet = fleet
    .filter(
      param =>
        param.lengthType === length ||
        param.lengthType === 'all' ||
        param.lengthType === null,
    )
    .filter(
      param =>
        param.heightType === height ||
        param.heightType === 'all' ||
        param.heightType === null,
    )
    .filter(param => parseFloat(param.lifting) <= 1.5)
    .filter(filterType);

  return (
    <ClickAwayListener disabled={!open} onClickAway={onClickAway}>
      <div
        className={clsx(classes.root, {
          [classes.open]: open,
        })}
      >
        <Params
          setHeight={param => setHeight(param)}
          setLength={param => setLength(param)}
          setType={param => setType(param)}
        />
        {load && (
          <div className={classes.scroll}>
            {/* <div className={classes.gradient} /> */}
            <Scroll>
              <div className={classes.fleetWrap}>
                <GridSpacingSmall className={classes.fleet}>
                  {filterFleet.map((item, index) => (
                    <Item
                      onClick={() => onClose(item)}
                      key={index}
                      item={item}
                      dev={dev}
                    />
                  ))}
                </GridSpacingSmall>
              </div>
            </Scroll>
            <Promo onClose={onClose} fleet={fleet} dev={dev} />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
