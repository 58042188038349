import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CntButton from './CntButton';
import num2str from '../lib/num2str';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 100,
    margin: '0 auto',
  },
  cntWrap: {
    height: '100%',
  },
  cnt: {
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  cntFix: {
    width: 24,
  },
}));

function checkValue(value) {
  if (value === 0.5) {
    return 30;
  }
  return value;
}

export default function Cnt({
  postfix,
  allCnt,
  cnt: cntCurrent,
  min = 0,
  max = 5,
  variants,
  disableBtns = false,
  onChange = () => null,
  ...props
}) {
  const classes = useStyles();

  const minCnt = variants ? variants[0] / 60 : min;
  const maxCnt = variants ? variants[variants.length - 1] / 60 : max;

  const cnt = variants ? cntCurrent / 60 : cntCurrent;

  useEffect(() => {
    if (allCnt === 0) return onChange(0);
  }, [allCnt, onChange]);

  function minus(min) {
    if (!!variants) {
      const index = variants.indexOf(cntCurrent);
      if (index > 0) {
        return onChange(variants[index - 1]);
      }
    }
    if (cnt > min) {
      const update = () => {
        onChange(cnt - 1);
      };
      return update();
    }
  }

  function plus(max) {
    if (!!variants) {
      const index = variants.indexOf(cntCurrent);
      if (index < max) {
        return onChange(variants[index + 1]);
      }
    }
    if (cnt < max && allCnt < max) {
      const update = () => {
        onChange(cnt + 1);
      };
      return update();
    }
  }

  return (
    <Grid {...props} container justify="space-between" className={classes.root}>
      {!disableBtns && (
        <Grid item>
          <CntButton
            onClick={() => minus(minCnt)}
            type="minus"
            disabled={cnt === minCnt}
          />
        </Grid>
      )}
      <Grid item xs={disableBtns && 12}>
        <Grid
          container
          className={classes.cntWrap}
          alignItems="center"
          justify="center"
        >
          <Typography
            className={clsx(classes.cnt, {
              [classes.cntFix]: !postfix || postfix.length - 1 < 3,
            })}
            component="p"
            align="center"
          >
            {variants ? checkValue(cntCurrent / 60) : cntCurrent}{' '}
            <Typography className={classes.cnt} component="span">
              {!!postfix && num2str(cnt, postfix)}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      {!disableBtns && (
        <Grid item>
          <CntButton
            onClick={() => plus(maxCnt)}
            type="plus"
            disabled={cnt === maxCnt || allCnt === maxCnt}
          />
        </Grid>
      )}
    </Grid>
  );
}
