import React, { useState } from 'react';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ConfirmBtn from '../ConfirmBtn';
import Popover from '../ui/Popover';
import AfterTime from './AfterTime';
import DateView from './DateView';
import CalendarView from './CalendarView';
import TimeView from './TimeView';
import TimePreview from './TimePreview';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(1008px / 3 - 2px)',
    height: 372,
    zIndex: 2,
  },
  dateView: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 80px rgba(37, 38, 49, 0.05)',
    borderRadius: 10,
    padding: '8px 4px 2px 4px',
    position: 'relative',
    marginBottom: 4,
    height: 85,
    transition: '.3s',
    zIndex: 1,
  },
  fullDateView: {
    height: 255,
  },
  timeView: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.05)',
    borderRadius: 10,
    height: 255,
    position: 'relative',
  },
  timePreview: {
    height: 85,
  },
  timing: {
    position: 'absolute',
    transition: '.3s',
    opacity: 1,
    visibility: 'visible',
    width: '100%',

    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
  },
  timingClose: {
    opacity: 0,
    visibility: 'hidden',
  },
}));

export default function DateTimePicker({
  setDate,
  open,
  onClose,
  anchorEl,
  ...props
}) {
  const classes = useStyles();

  const currentDate = new Date();
  const minDate = addMinutes(currentDate, 15);
  const [dateChecked, setDateChecked] = useState(minDate);
  const [fullCalendar, setFullCalendar] = useState(false);

  const currentDateInit = format(currentDate, 'yyyy-MM-dd');
  const newDateInit = format(dateChecked, 'yyyy-MM-dd');
  const today = currentDateInit === newDateInit;

  function onClick() {
    setDate(dateChecked);
    onClose();
  }

  return (
    <Popover open={open} anchorEl={anchorEl} onClose={onClose}>
      <div {...props} className={classes.root}>
        <div
          className={clsx(classes.dateView, {
            [classes.fullDateView]: fullCalendar,
          })}
        >
          <CalendarView
            date={dateChecked}
            onChange={setDateChecked}
            open={fullCalendar}
          />
          <DateView
            date={dateChecked}
            setDate={setDateChecked}
            open={!fullCalendar}
            setOpen={() => setFullCalendar(true)}
          />
        </div>
        <div
          className={clsx(classes.timeView, {
            [classes.timePreview]: fullCalendar,
          })}
        >
          <div
            className={clsx(classes.timing, {
              [classes.timingClose]: fullCalendar,
            })}
          >
            <TimeView
              today={today}
              date={dateChecked}
              setDate={setDateChecked}
            />
            <AfterTime setDate={setDateChecked} />
          </div>
          <TimePreview
            date={dateChecked}
            open={fullCalendar}
            onClick={() => setFullCalendar(false)}
          />
        </div>
        <ConfirmBtn onClick={onClick}>Подтвердить</ConfirmBtn>
      </div>
    </Popover>
  );
}
