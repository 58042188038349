import React from 'react';
import Input from './Input';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ClearRounded from '@material-ui/icons/ClearRounded';
import Button from '../ui/Button';
import Geo from '../icons/Geo';

const useStyles = makeStyles(theme => ({
  changeWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteBtn: {
    padding: 0,
    width: 16,
    height: '100%',
    background: '#FFFFFF',
    border: '1px solid #F2F7FB',
    borderRadius: 5,
    minWidth: 'auto',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& $iconDelete': {
      color: '#E3EAFA',
      fontSize: theme.typography.pxToRem(11),
    },

    '&:hover': {
      borderColor: theme.palette.primary.main,

      '& $iconDelete': {
        color: theme.palette.primary.main,
      },
    },
  },
  iconDelete: {
    transition: '.3s',
  },
  street: {
    position: 'relative',

    '&:before': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: 22,
      background: '#E3EAFA',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
}));

export default function InputField({
  index,
  streetsQuery,
  housesQuery,
  shrink,
  dispatchShrink,
  loc,
  setActive,
  setOpenMap,
  focusInputLine,
  setFocusInputLine,
  setActiveField,
  dispatchStreetsQuery,
  dispatchHousesQuery,
  setOpenResult,
  dispatchInputFields,
  ...props
}) {
  const classes = useStyles();

  function setQuery(e, index, type) {
    switch (type) {
      case 'street':
        dispatchStreetsQuery({
          type: 'change',
          payload: { [index]: e.target.value },
        });
        break;
      case 'house':
        dispatchHousesQuery({
          type: 'change',
          payload: { [index]: e.target.value },
        });
        break;
      default:
        break;
    }
    setOpenResult(true);
  }

  function onFocus(index, type) {
    dispatchShrink({
      type: 'change',
      payload: { [index]: true },
    });
    setActive(true);

    if (index !== focusInputLine) {
      setFocusInputLine(index);
    }

    switch (type) {
      case 'street':
        setActiveField('street');
        break;
      case 'house':
        setActiveField('house');
        break;
      default:
        break;
    }
  }

  function openMap(index) {
    if (index !== focusInputLine) {
      setFocusInputLine(index);
    }
    setOpenMap(true);
  }

  function onBlur() {
    setActive(false);
  }

  const disabledHouse = index => {
    if (loc && loc[index]) {
      if (loc[index].street && loc[index].street.id) {
        return false;
      }
      return true;
    }
    return true;
  };

  function deleteAddress(index) {
    dispatchInputFields({ type: 'delete', payload: index });
  }

  function checkStreet() {
    if (streetsQuery && streetsQuery[index]) {
      return streetsQuery[index];
    }
    return '';
  }

  function checkHouse() {
    if (!!housesQuery && housesQuery[index]) {
      return housesQuery[index];
    }
    return '';
  }

  return (
    <Grid container spacing={3} {...props}>
      <Grid item xs={7}>
        <Input
          placeholder="Улица"
          name={`street[${index}]`}
          onChange={e => setQuery(e, index, 'street')}
          value={checkStreet()}
          InputLabelProps={{
            shrink: shrink && shrink[index],
          }}
          onFocus={() => onFocus(index, 'street')}
          onBlur={onBlur}
          className={classes.street}
        />
      </Grid>
      <Grid item xs={2}>
        <Input
          placeholder="Дом"
          name={`house[${index}]`}
          onChange={e => setQuery(e, index, 'house')}
          value={checkHouse()}
          InputProps={{
            disabled: disabledHouse(index),
          }}
          InputLabelProps={{
            shrink: shrink && shrink[index],
          }}
          onFocus={() => onFocus(index, 'house')}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={3} className={classes.changeWrap}>
        <Grid container>
          <Grid item xs={6}>
            <IconButton color="primary" onClick={() => openMap(index)}>
              <Geo color="primary" />
            </IconButton>
          </Grid>
          {index > 1 && (
            <Grid item xs={6}>
              <Button
                onClick={() => deleteAddress(index)}
                className={classes.deleteBtn}
                color="primary"
              >
                <ClearRounded className={classes.iconDelete} />
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
