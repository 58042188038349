import React from 'react';
import Mask from 'react-input-mask';

export default function InputMask({ inputRef, ...props }) {
  return (
    <Mask
      {...props}
      alwaysShowMask
      mask="+7 (999) 999-99-99"
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
}
