export const initialLoadedStates = {
  options: false,
  carClasses: false,
};

export function loadedReducer(state, action) {
  switch (action.type) {
    case 'optionsLoaded':
      return { ...state, options: true };
    case 'carClassesLoaded':
      return { ...state, carClasses: true };
    default:
      throw new Error();
  }
}

export const initialParamsStates = {
  loc: [],
  json_data: {},
};

export function calcParamsReducer(state, action) {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        ...action.payload,
      };
    case 'addJSON':
      return {
        ...state,
        json_data: {
          ...state.json_data,
          ...action.payload,
        },
      };
    case 'reset':
      return {
        ...state,
        loc: [],
      };
    default:
      throw new Error();
  }
}

export const initialOrderStates = {};

export function orderReducer(state, action) {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        ...action.payload,
      };
    case 'reset':
      return {
        ...state,
        rand_id: null,
        trans_id: null,
        order_addresses: null,
        jtoken_price_gr: null,
        discount_proc_used: null,
        dist: null,
        price_hash: null,
        promo_discount_price: null,
        order_c_auto: null,
        user_phone: null,
        user_code: null,
      };
    default:
      throw new Error();
  }
}

export const initialInputResultsStates = {};

export function inputResultsReducer(state, action) {
  switch (action.type) {
    case 'change':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

export const initialStreetsQueryStates = {};

export function streetsQueryReducer(state, action) {
  switch (action.type) {
    case 'change':
      return { ...state, ...action.payload };
    case 'delete':
      const index = action.payload;
      delete state[index];
      return { ...state };
    case 'reset':
      return {};
    default:
      throw new Error();
  }
}

export const initialHousesQueryStates = {};

export function housesQueryReducer(state, action) {
  switch (action.type) {
    case 'change':
      return { ...state, ...action.payload };
    case 'delete':
      const index = action.payload;
      delete state[index];
      return { ...state };
    case 'reset':
      return {};
    default:
      throw new Error();
  }
}

export const initialLocStates = {};

export function locReducer(state, action) {
  switch (action.type) {
    case 'change':
      return { ...state, ...action.payload };
    case 'delete':
      const index = action.payload;
      delete state[index];
      return { ...state };
    case 'reset':
      return {};
    default:
      throw new Error();
  }
}

export const initialShrinkStates = {};

export function shrinkReducer(state, action) {
  switch (action.type) {
    case 'change':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

export const initialMarkersStates = {};

export function markersReducer(state, action) {
  switch (action.type) {
    case 'change':
      return { ...state, ...action.payload };
    case 'delete':
      const index = action.payload;
      delete state[index];
      return { ...state };
    case 'reset':
      return {};
    default:
      throw new Error();
  }
}
