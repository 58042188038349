import React, { useRef, useEffect, useState } from 'react';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import addMinutes from 'date-fns/addMinutes';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { hours, minutes, checkTime } from '../../lib/time';
import TimeItem from './TimeItem';

const useStyles = makeStyles((theme) => ({
  picker: {
    position: 'relative',
    height: 165,
    overflow: 'hidden',

    '&:before': {
      content: '""',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute',
      width: '100%',
      height: 66,
      borderTop: '1px solid rgba(74, 100, 126, 0.1)',
      borderBottom: '1px solid rgba(74, 100, 126, 0.1)',
    },
  },
  row: {
    width: 48,

    '&:first-child': {
      [theme.breakpoints.up('sm')]: {
        overflow: 'hidden',
      },
    },
    '&:last-child': {
      [theme.breakpoints.up('sm')]: {
        overflow: 'hidden',
      },
    },
  },
  divider: {
    fontSize: theme.typography.pxToRem(24),
    marginTop: -4,
  },

  itemsWrap: {
    scrollSnapType: 'y mandatory',
    height: 198,
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(0) !important',
      padding: '66px 0',
      overflowY: 'scroll',
    },
  },
}));

export default function TimeView({ today, date, setDate }) {
  const classes = useStyles();

  const currentDate = new Date();
  const minDate = addMinutes(currentDate, 15);

  const currentHours = parseInt(minDate.getHours());
  const currentMinutes = parseInt(minDate.getMinutes());

  const hourCurrentIndex = hours.indexOf(currentHours);
  const minuteCurrentIndex = minutes.indexOf(currentMinutes);

  const checkedHours = parseInt(date.getHours());
  const checkedMinutes = parseInt(date.getMinutes());

  const hourCheckedIndex = hours.indexOf(checkedHours);
  const minuteCheckedIndex = minutes.indexOf(checkedMinutes);

  const scrollHourPx = 0 - 66 * (hourCheckedIndex - 1);
  const scrollMinutePx = 0 - 66 * (minuteCheckedIndex - 1);

  const hoursRef = useRef();
  const minutesRef = useRef();
  const hoursEl = hoursRef.current;
  const minutesEl = minutesRef.current;

  const [init, setInit] = useState(false);

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (today) {
      if (checkedHours < currentHours) {
        setDate(minDate);
      }
      if (checkedMinutes < currentMinutes && currentHours === checkedHours) {
        setDate(minDate);
      }
    }
  }, [
    currentMinutes,
    currentHours,
    today,
    checkedMinutes,
    checkedHours,
    setDate,
    minDate,
  ]);

  useEffect(() => {
    if (hoursEl) {
      hoursEl.scroll({
        top: hourCheckedIndex * 66,
      });
    }
    if (minutesEl) {
      minutesEl.scroll({
        top: minuteCheckedIndex * 66,
      });
    }
  }, [hourCheckedIndex, minuteCheckedIndex, hoursEl, minutesEl]);

  function scrollHour(e) {
    if (!mobile) {
      if (e.deltaY < 0) {
        const index = (0 - scrollHourPx) / 66;
        const time = index.toString().length === 1 ? `0${index}` : index;
        const past = checkTime('hours', today, date, time);
        if (scrollHourPx <= 0 && past) {
          const newDate = setHours(date, index);
          setDate(newDate);
        }
      } else {
        const index = (0 - scrollHourPx) / 66 + 2;
        const time = index.toString().length === 1 ? `0${index}` : index;
        const past = checkTime('hours', today, date, time);
        if (scrollHourPx >= 0 - 66 * (hours.length - 3) && past) {
          const newDate = setHours(date, index);
          setDate(newDate);
        }
      }
    }
  }

  function scrollMinute(e) {
    if (!mobile) {
      if (e.deltaY < 0) {
        const index = (0 - scrollMinutePx) / 66;
        const time = index.toString().length === 1 ? `0${index}` : index;
        const past = checkTime('minutes', today, date, time);
        if (scrollMinutePx <= 0 && past) {
          const newDate = setMinutes(date, index);
          setDate(newDate);
        }
      } else {
        const index = (0 - scrollMinutePx) / 66 + 2;
        const time = index.toString().length === 1 ? `0${index}` : index;
        const past = checkTime('minutes', today, date, time);
        if (scrollMinutePx >= 0 - 66 * (minutes.length - 3) && past) {
          const newDate = setMinutes(date, index);
          setDate(newDate);
        }
      }
    }
  }

  function onClickHour(index) {
    const time = index.toString().length === 1 ? `0${index}` : index;
    const past = checkTime('hours', today, date, time);
    if (past && !mobile) {
      const newDate = setHours(date, index);
      setDate(newDate);
    }
  }

  function onClickMinute(index) {
    const time = index.toString().length === 1 ? `0${index}` : index;
    const past = checkTime('minutes', today, date, time);
    if (past && !mobile) {
      const newDate = setMinutes(date, index);
      setDate(newDate);
    }
  }

  if (!init && mobile) {
    setInit(true);
    if (hoursEl) {
      hoursEl.scroll({
        top: hourCurrentIndex * 66,
      });
    }
    if (minutesEl) {
      minutesEl.scroll({
        top: minuteCurrentIndex * 66,
      });
    }
  }

  let hourTimeout;
  function hourScroll() {
    if (hourTimeout) {
      clearTimeout(hourTimeout);
    }
    hourTimeout = setTimeout(() => {
      if (hoursEl) {
        const scrollToIndex = Math.round(hoursEl.scrollTop / 66);

        const past = checkTime('hours', today, date, scrollToIndex);
        if (!past) {
          const newDate = setHours(date, currentHours);
          setDate(newDate);

          hoursEl.scroll({
            top: currentHours * 66,
            behavior: 'smooth',
          });
        } else {
          const newDate = setHours(date, scrollToIndex);
          setDate(newDate);
        }
      }
    }, 66);
  }

  let minuteTimeout;
  function minuteScroll() {
    if (minuteTimeout) {
      clearTimeout(minuteTimeout);
    }
    minuteTimeout = setTimeout(() => {
      if (minutesEl) {
        const scrollToIndex = Math.round(minutesEl.scrollTop / 66);

        const past = checkTime('minutes', today, date, scrollToIndex);
        if (!past) {
          const newDate = setMinutes(date, currentMinutes);
          setDate(newDate);

          minutesEl.scroll({
            top: currentMinutes * 66,
            behavior: 'smooth',
          });
        } else {
          const newDate = setMinutes(date, scrollToIndex);
          setDate(newDate);
        }
      }
    }, 66);
  }

  return (
    <Grid
      container
      className={classes.picker}
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item className={classes.row} onWheel={(e) => scrollHour(e)}>
        <Grid
          container
          style={{ transform: `translateY(${scrollHourPx}px)` }}
          className={classes.itemsWrap}
          onScroll={hourScroll}
          ref={hoursRef}
        >
          {hours.map((hour, index) => (
            <TimeItem
              key={index}
              active={hourCheckedIndex === index}
              onClick={() => onClickHour(index)}
            >
              {hour}
            </TimeItem>
          ))}
        </Grid>
      </Grid>
      <Grid item className={classes.row}>
        <Typography className={classes.divider} align="center">
          :
        </Typography>
      </Grid>
      <Grid item className={classes.row} onWheel={(e) => scrollMinute(e)}>
        <Grid
          container
          style={{
            transform: `translateY(${scrollMinutePx}px)`,
          }}
          className={classes.itemsWrap}
          onScroll={minuteScroll}
          ref={minutesRef}
        >
          {minutes.map((minute, index) => (
            <TimeItem
              key={index}
              active={minuteCheckedIndex === index}
              onClick={() => onClickMinute(index)}
            >
              {minute.toString().length === 1 ? `0${minute}` : minute}
            </TimeItem>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
