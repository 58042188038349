import React, { useState, useReducer, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Block from '../Block';
import { useQuery } from '@apollo/react-hooks';
import { GET_STREET_QUERY, GET_BUILDING_QUERY } from '../../lib/query';
import {
  initialInputResultsStates,
  inputResultsReducer,
  initialShrinkStates,
  shrinkReducer,
} from '../../lib/reducer';
import Results from './Results';
import InputField from './InputField';
import AddRounded from '@material-ui/icons/AddRounded';
import Location from '../icons/Location';
import ClickAwayListener from '../ClickAwayListener';

const useStyles = makeStyles(theme => ({
  block: {
    position: 'absolute',
    width: 'calc(100% - 4px)',
    zIndex: 1,
  },
  root: {
    padding: '24px 0',
    height: '100%',
  },
  deleteRound: {
    borderRadius: '10px 10px 0 0',
  },
  inputs: {
    height: '100%',
    overflow: 'hidden',
  },
  inputsWrap: {
    height: '100%',
    flexGrow: 0,
    maxWidth: 'calc(100% - 44px)',
    flexBasis: 'calc(100% - 44px)',
  },
  marks: {
    height: '100%',
    position: 'relative',
    flexGrow: 0,
    maxWidth: 44,
    flexBasis: 44,

    '&:before': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: 2,
      background: dev =>
        `url(${
          dev
            ? '/images/dot.svg'
            : 'https://order-widget.gruzovichkof.ru/images/dot.svg'
        })`,
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  pointWrap: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
    position: 'relative',

    '&:first-child': {
      padding: '2px 0 4px',
    },
    '&:last-child': {
      padding: '4px 0 14px',
    },
  },
  pointFrom: {},
  pointTo: {
    width: 16,
    height: 16,
    background: theme.palette.primary.main,
    borderRadius: '50%',

    '& $iconAdd': {
      fontSize: '1rem',
      color: '#ffffff',
    },
  },
  hr: {
    border: 'none',
    width: 'calc(100% - 48px)',
    margin: 0,
    height: 3,
    background: dev =>
      `url(${
        dev
          ? '/images/dash.svg'
          : 'https://order-widget.gruzovichkof.ru/images/dash.svg'
      })`,
    borderRadius: 12,
  },
  iconAdd: {},
  addBtn: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function Address({
  setLoc: setLocAddress,
  setOrderAddresses,
  error,
  setError,
  setOpenMap,
  streetsQuery,
  housesQuery,
  loc,
  dispatchStreetsQuery,
  dispatchHousesQuery,
  dispatchLoc,
  focusInputLine,
  setFocusInputLine,
  dispatchMarkers,
  inputFields,
  dispatchInputFields,
  city,
  className,
  dev,
}) {
  const classes = useStyles(dev);
  const [active, setActive] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [openResult, setOpenResult] = useState(false);
  const [streetQuerySkip, setStreetQuerySkip] = useState(true);
  const [streetData, setStreetsData] = useState(null);
  const [houseQuerySkip, setHouseQuerySkip] = useState(true);
  const [houseData, setHouseData] = useState(null);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  function checkError(error) {
    if (error && error.address) {
      return true;
    }
    return false;
  }

  const { id: cityId, radius, lat, lng, name } = city;

  const [shrink, dispatchShrink] = useReducer(
    shrinkReducer,
    initialShrinkStates,
  );

  const [inputResults, dispatchInputResults] = useReducer(
    inputResultsReducer,
    initialInputResultsStates,
  );

  const maxInputFields = Object.values(inputFields).length - 1;

  const currentStreetsQuery = streetsQuery && streetsQuery[focusInputLine];
  const currentHousesQuery = housesQuery && housesQuery[focusInputLine];

  const { loading: streetLoading } = useQuery(GET_STREET_QUERY, {
    skip: streetQuerySkip,
    variables: {
      city: cityId,
      radius,
      lat,
      lng,
      query: currentStreetsQuery,
    },
    onCompleted: data => {
      setStreetsData(data);
      setStreetQuerySkip(true);
    },
  });

  const { loading: buildingLoading } = useQuery(GET_BUILDING_QUERY, {
    skip: houseQuerySkip,
    variables: {
      city: cityId,
      hwid:
        loc[focusInputLine] &&
        loc[focusInputLine].street &&
        loc[focusInputLine].street.id,
      query: currentHousesQuery,
    },
    onCompleted: data => {
      setHouseData(data);
      setHouseQuerySkip(true);
    },
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (checkError(error)) {
        setError({ ...error, address: null });
      }
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [error, setError]);

  useEffect(() => {
    const queryArray = streetsQuery ? Object.values(streetsQuery) : [];
    queryArray.forEach((_, index) => {
      if (
        streetsQuery &&
        index === focusInputLine &&
        streetsQuery[index] &&
        streetsQuery[index].length > 2
      ) {
        setStreetQuerySkip(false);
        if (!streetLoading && streetData) {
          const { searchStreet } = streetData;
          dispatchInputResults({
            type: 'change',
            payload: {
              [index]: { street: searchStreet },
            },
          });
        }
      }

      if (
        streetsQuery &&
        index === focusInputLine &&
        streetsQuery[index] &&
        streetsQuery[index].length <= 2
      ) {
        dispatchInputResults({
          type: 'change',
          payload: { [index]: { street: '' } },
        });
      }
    });
    return () => {
      setStreetQuerySkip(true);
    };
  }, [streetsQuery, streetData, streetLoading, focusInputLine]);

  useEffect(() => {
    const queryArray = housesQuery ? Object.values(housesQuery) : [];
    queryArray.forEach(() => {
      if (
        housesQuery &&
        housesQuery[focusInputLine] &&
        housesQuery[focusInputLine].length > 0
      ) {
        setHouseQuerySkip(false);
        if (!buildingLoading && houseData) {
          const { searchBuilding } = houseData;
          dispatchInputResults({
            type: 'change',
            payload: {
              [focusInputLine]: { house: searchBuilding },
            },
          });
        }
      }

      if (
        housesQuery &&
        housesQuery[focusInputLine] &&
        housesQuery[focusInputLine].length < 1
      ) {
        dispatchInputResults({
          type: 'change',
          payload: { [focusInputLine]: { house: '' } },
        });
      }
    });
    return () => {
      setHouseQuerySkip(true);
    };
  }, [housesQuery, houseData, buildingLoading, focusInputLine]);

  useEffect(() => {
    const locArray = loc ? Object.values(loc) : [];
    let locArrayResult = [];
    let orderAddresses = [];
    locArray.forEach(({ street, house, manual = 0, lngLat }) => {
      const loc = `${lngLat.lat},${lngLat.lng}`;
      const streetName = street ? street.name : '';
      const houseName = house ? house.name : '';
      const cityName = street ? street.city : '';
      if (loc) {
        const orderLoc = loc.replace(',', ':');
        locArrayResult.push(loc);
        orderAddresses.push(
          `${orderLoc}:${streetName}:${houseName}::${manual}:${cityName};`,
        );
      }
    });

    if (locArrayResult.length > 1) {
      setLocAddress({
        type: 'add',
        payload: {
          loc: locArrayResult,
        },
      });
      setOrderAddresses({
        order_addresses: orderAddresses.join(''),
      });
    }
  }, [loc, setLocAddress, setOrderAddresses]);

  function setLoc(index, locName, type, value) {
    const lngLat = { lng: value.lng, lat: value.lat };

    dispatchLoc({
      type: 'change',
      payload: {
        ...loc,
        [index]: { ...loc[index], [type]: value, lngLat },
      },
    });

    dispatchMarkers({
      type: 'change',
      payload: {
        [index]: [value.lng, value.lat],
      },
    });

    switch (type) {
      case 'street':
        dispatchStreetsQuery({
          type: 'change',
          payload: { [index]: locName },
        });
        break;
      case 'house':
        dispatchHousesQuery({
          type: 'change',
          payload: { [index]: locName },
        });
        break;
      default:
        break;
    }

    setOpenResult(false);
  }

  function onClickAway(index) {
    if (!loc[index]) {
      dispatchShrink({
        type: 'change',
        payload: { [index]: false },
      });
      dispatchStreetsQuery({
        type: 'delete',
        payload: index,
      });
      dispatchHousesQuery({
        type: 'delete',
        payload: index,
      });
    } else {
      if (!loc[index].house) {
        dispatchHousesQuery({
          type: 'delete',
          payload: index,
        });
      }
    }
    setOpenResult(false);
    for (const key in loc) {
      if (loc.hasOwnProperty(key)) {
        const el = loc[key];
        if (el.street) {
          dispatchStreetsQuery({
            type: 'change',
            payload: { [key]: loc[key].street.name },
          });
        }
        if (el.house) {
          dispatchHousesQuery({
            type: 'change',
            payload: { [key]: loc[key].house.name },
          });
        }
      }
    }
  }

  const results = inputResults ? inputResults[focusInputLine] : null;

  function addAddress() {
    if (
      loc[0] &&
      loc[0].street &&
      loc[maxInputFields] &&
      loc[maxInputFields].street
    ) {
      dispatchInputFields({
        type: 'change',
        payload: {
          [maxInputFields + 1]: {
            index: maxInputFields + 1,
          },
        },
      });
    } else {
      setError({ ...error, address: true });
    }
  }

  return (
    <>
      <Block
        alignItems="stretch"
        active={active}
        className={clsx(className, classes.block)}
        error={checkError(error)}
      >
        <ClickAwayListener
          disabled={!openResult || mobile}
          onClickAway={() => onClickAway(focusInputLine)}
        >
          <Grid item xs={12}>
            <Grid container alignItems="center" className={classes.root}>
              <Grid item md={2} xs={2} className={classes.marks}>
                <Grid
                  container
                  className={classes.inputs}
                  justify="space-between"
                  alignItems="center"
                  direction="column"
                >
                  <Grid item className={classes.pointWrap}>
                    <div className={classes.pointFrom}>
                      <Location />
                    </div>
                  </Grid>
                  <Grid item className={classes.pointWrap}>
                    <div className={classes.pointTo}>
                      <IconButton
                        onClick={addAddress}
                        className={classes.addBtn}
                        color="primary"
                      >
                        <AddRounded className={classes.iconAdd} />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={10} xs={10} className={classes.inputsWrap}>
                <Grid
                  container
                  className={classes.inputs}
                  alignContent="space-between"
                >
                  {Object.values(inputFields).map(
                    ({ index: fieldIndex }, index) => (
                      <>
                        <InputField
                          index={fieldIndex}
                          streetsQuery={streetsQuery}
                          housesQuery={housesQuery}
                          shrink={shrink}
                          dispatchShrink={dispatchShrink}
                          loc={loc}
                          setActive={setActive}
                          setOpenMap={setOpenMap}
                          focusInputLine={focusInputLine}
                          setFocusInputLine={setFocusInputLine}
                          setActiveField={setActiveField}
                          dispatchStreetsQuery={dispatchStreetsQuery}
                          dispatchHousesQuery={dispatchHousesQuery}
                          setOpenResult={setOpenResult}
                          dispatchInputFields={dispatchInputFields}
                          key={index}
                        />
                        {index !== maxInputFields && (
                          <hr className={classes.hr} />
                        )}
                      </>
                    ),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Block>
      <Results
        activeField={activeField}
        results={results}
        city={name}
        open={openResult}
        locField={focusInputLine}
        onClick={setLoc}
      />
    </>
  );
}
