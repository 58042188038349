const city = [
  {
    id: 1,
    radius: 565,
    lat: 59.934397,
    lng: 30.326882,
    name: 'Санкт-Петербург',
    phone: '+7 (812) 245-37-71',
    ymId: 35484350,
    card_name_std: 503169,
    discount: 10,
  },
  {
    id: 29,
    radius: 565,
    lat: 55.75222,
    lng: 37.61556,
    name: 'Москва',
    phone: '+7 (495) 150-00-00',
    ymId: 35484630,
    card_name_std: 760351,
    discount: 10,
  },
  {
    id: 6,
    radius: 565,
    lat: 53.203572,
    lng: 50.160968,
    name: 'Самара',
    phone: '+7 (846) 300-22-00',
    ymId: 45314484,
  },
  {
    id: 4,
    radius: 565,
    lat: 54.70739,
    lng: 20.507307,
    name: 'Калининград',
    phone: '+7 (4012) 40-05-95',
    ymId: 38871555,
  },
  {
    id: 49,
    radius: 565,
    lat: 54.312416,
    lng: 48.395622,
    name: 'Ульяновск',
    phone: '+7 (842) 231-48-01',
    ymId: 54924613,
  },
  {
    id: 12,
    radius: 565,
    lat: 55.798551,
    lng: 49.106324,
    name: 'Казань',
    phone: '+7 (843) 21-21-555',
    ymId: 46825173,
  },
  {
    id: 24,
    radius: 565,
    lat: 48.472584,
    lng: 135.057732,
    name: 'Хабаровск',
    phone: '+7 (4212) 78-82-82',
    ymId: 48054599,
  },
  {
    id: 54,
    radius: 565,
    lat: 55.354968,
    lng: 86.087314,
    name: 'Кемерово',
    phone: '+7 (3842) 49-10-10',
    ymId: 56755312,
  },
  {
    id: 42,
    radius: 565,
    lat: 64.539393,
    lng: 40.516939,
    name: 'Архангельск',
    phone: '+7 (8182) 42-18-42',
    ymId: 53400238,
  },
  {
    id: 20,
    radius: 565,
    lat: 47.222531,
    lng: 39.718705,
    name: 'Ростов-на-Дону',
    phone: '+7 (863) 303-20-02',
    ymId: 37318065,
  },
  {
    id: 43,
    radius: 565,
    lat: 62.028098,
    lng: 129.732555,
    name: 'Якутск',
    phone: '+7 (4112) 455-555',
    ymId: 53658448,
  },
  {
    id: 11,
    radius: 565,
    lat: 54.734773,
    lng: 55.957829,
    name: 'Уфа',
    phone: '+7 (347) 2000-600',
    ymId: 65359225,
  },
  {
    id: 38,
    radius: 565,
    lat: 43.116418,
    lng: 131.882475,
    name: 'Владивосток',
    phone: '+7 (423) 265 95 95',
    ymId: 52574713,
  },
  {
    id: 21,
    radius: 565,
    lat: 56.838607,
    lng: 60.605514,
    name: 'Екатеринбург',
    phone: '+7 (343) 222-33-33',
    ymId: 38613025,
  },
  {
    id: 7,
    radius: 565,
    lat: 56.010569,
    lng: 92.852545,
    name: 'Красноярск',
    phone: '+7 (391) 279-00-79',
    ymId: 45359268,
  },
  {
    id: 65,
    radius: 565,
    lat: 55.743553,
    lng: 52.39582,
    name: 'Набережные Челны',
    phone: '+7 (8552) 99-99-90',
    ymId: 65169406,
  },
  {
    id: 108,
    radius: 565,
    lat: 59.57639,
    lng: 30.12833,
    name: 'Гатчина',
    phone: '+7 (81371) 23-0-32',
    ymId: 65652460,
  },
  {
    id: 10,
    radius: 565,
    lat: 52.286387,
    lng: 104.28066,
    name: 'Иркутск',
    phone: '+7 (3952) 787-000',
    ymId: 45922014,
  },
  {
    id: 39,
    radius: 565,
    lat: 54.507014,
    lng: 36.252277,
    name: 'Калуга',
    phone: '+7 (4842) 21-00-01',
    ymId: 52579330,
  },
  {
    id: 70,
    radius: 565,
    lat: 55.855537,
    lng: 38.441058,
    name: 'Ногинск',
    phone: '+7 (49651) 6-49-39',
    ymId: 64733548,
  },
  {
    id: 102,
    radius: 565,
    lat: 58.521475,
    lng: 31.275475,
    name: 'Великий Новгород',
    phone: '+7 (8162) 68-23-28',
    ymId: 65359351,
  },
  {
    id: 17,
    radius: 565,
    lat: 55.030199,
    lng: 82.92043,
    name: 'Новосибирск',
    phone: '+7 (383) 383-22-23',
    ymId: 39856035,
  },
  {
    id: 18,
    radius: 565,
    lat: 54.989342,
    lng: 73.368212,
    name: 'Омск',
    phone: '+7 (3812) 353-000',
    ymId: 43216929,
  },
  {
    id: 19,
    radius: 565,
    lat: 58.010259,
    lng: 56.234195,
    name: 'Пермь',
    phone: '+7 (342) 254-05-50',
    ymId: 40150245,
  },
  {
    id: 25,
    radius: 565,
    lat: 54.782526,
    lng: 32.044128,
    name: 'Смоленск',
    phone: '+7 (4812) 25-00-25',
    ymId: 48181157,
  },
  {
    id: 26,
    radius: 565,
    lat: 52.721246,
    lng: 41.452238,
    name: 'Тамбов',
    phone: '+7 (4752) 42-72-72',
    ymId: 48568403,
  },
  {
    id: 9,
    radius: 565,
    lat: 54.193033,
    lng: 37.617752,
    name: 'Тула',
    phone: '+7 (4872) 255-255',
    ymId: 45802881,
    minDeliveryTime: 30,
  },
  {
    id: 47,
    radius: 565,
    lat: 43.797279,
    lng: 131.951753,
    name: 'Уссурийск',
    phone: '+7 (4234) 21-00-00',
    ymId: 54266922,
  },
  {
    id: 22,
    radius: 565,
    lat: 51.661535,
    lng: 39.200287,
    name: 'Воронеж',
    phone: '+7 (473) 205-00-00',
    ymId: 39766865,
  },
  {
    id: 103,
    radius: 565,
    lat: 60.01512,
    lng: 30.67314,
    name: 'Всеволожск',
    phone: '+7 (81370) 18-0-18',
    ymId: 65464273,
  },
];

export default city;
