import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from './ui/Button';

const useStyles = makeStyles(theme => ({
  wrap: {
    width: '100%',
    minHeight: 260,
    height: 'auto',

    opacity: 0,
    visibility: 'hidden',

    background: '#FFFFFF',
    borderRadius: 16,

    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 99,

    transition: '.3s',

    [theme.breakpoints.down('sm')]: {
      top: 0,
      transform: 'translateX(0)',
      height: '100%',
    },

    '& $subTitle': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(10),
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    '& $title': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(26),
      margin: '8px 0 16px 0',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    '& $text': {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(26),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  open: {
    opacity: 1,
    visibility: 'visible',
  },
  root: {
    border: '2px solid #F7F7F9',
    borderRadius: 16,
    height: '100%',
    minHeight: 'inherit',
  },
  content: {
    height: '100%',
    minHeight: 'inherit',
  },
  subTitle: {},
  title: {},
  text: {},
  btnWrap: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  btn: {
    height: 56,
    maxWidth: 252,
    width: '100%',

    '& $btnText': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
    },
  },
  btnText: {},
  textContent: {
    padding: '52px 0 32px 52px',
    [theme.breakpoints.down('sm')]: {
      padding: '90px 24px 0 24px',
    },
  },
  btnContent: {
    position: 'relative',
    padding: '52px 32px 32px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px 32px 24px',
    },
  },
  img: {
    padding: 8,
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    zIndex: -1,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 370,
    },
  },
  highlight: {
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
  },
  column: {
    top: 0,
    transform: 'translateX(0)',
    height: '100%',

    '& $subTitle': {
      textAlign: 'center',
    },

    '& $title': {
      textAlign: 'center',
    },

    '& $text': {
      textAlign: 'center',
    },

    '& $btnWrap': {
      justifyContent: 'center',
    },

    '& $textContent': {
      padding: '24px 24px 0 24px',
    },

    '& $btnContent': {
      padding: '0 24px 32px 24px',
    },
  },
}));

export default function Result({ status, onReset, city, dev, direction }) {
  const classes = useStyles();
  const [time, setTime] = useState(5);
  const isColumn = direction === 'column';

  function onClick() {
    onReset();
  }

  useEffect(() => {
    let timer;
    if (time > 0 && !!status) {
      timer = setTimeout(() => {
        setTime(prev => prev - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [time, status]);

  return (
    <div
      className={clsx(classes.wrap, {
        [classes.open]: !!status && status !== 'success',
        [classes.column]: isColumn,
      })}
    >
      <div className={classes.root}>
        <Grid container className={classes.content}>
          <Grid
            item
            md={isColumn ? 12 : 7}
            xs={12}
            className={classes.textContent}
          >
            <Typography className={classes.subTitle}>
              {status === 'success'
                ? 'Ваш заказ успешно оформлен'
                : 'Возникла ошибка'}
            </Typography>
            <Typography className={classes.title}>
              {status === 'success'
                ? 'Спасибо, что выбрали сервис ГрузовичкоФ.'
                : 'Упс...'}
            </Typography>
            <Typography className={classes.text}>
              {status === 'success' ? (
                <>
                  За 15 минут до подачи автомобиля вам придёт sms-сообщение с
                  информацией о водителе. Если Вы хотите внести изменения в
                  заказ, позвоните по номеру{' '}
                  <span className={classes.highlight}>{city.phone}</span>.
                  <br />
                  <Typography
                    className={classes.text}
                    component="span"
                    color="primary"
                  >
                    <i>
                      В это непростое время, мы следим за здоровьем наших
                      экипажей и сотрудников, и постоянно проводим дезинфекцию
                      автомобилей. Желаем Вам и вашим близким хорошего
                      настроения и крепкого здоровья, не смотря ни на что!
                    </i>
                  </Typography>
                  <br />
                  Приятного переезда!
                </>
              ) : (
                <>
                  Пожалуйста, позвоните по номеру{' '}
                  <span className={classes.highlight}>{city.phone}</span>
                </>
              )}
            </Typography>
          </Grid>
          <Grid
            item
            md={isColumn ? 12 : 5}
            xs={12}
            className={classes.btnContent}
          >
            <img
              src={`${
                dev
                  ? `/images/result.svg`
                  : `https://order-widget.gruzovichkof.ru/images/result.svg`
              }`}
              className={classes.img}
              alt=""
            />
            {status !== 'faildLoad' && (
              <Box
                alignItems="flex-end"
                display="flex"
                height="100%"
                className={classes.btnWrap}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  onClick={onClick}
                  disabled={time > 0 ? true : false}
                >
                  <Typography className={classes.btnText}>
                    {time > 0 ? `Закрыть через ${time}` : 'Закрыть'}
                  </Typography>
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
