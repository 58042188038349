import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  priceWrap: {
    position: 'absolute',
    bottom: -28,
    right: 16,

    [theme.breakpoints.down('sm')]: {
      position: 'static',
      overflow: 'hidden',
    },
  },
  root: {
    '& $discount': {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'normal',
    },
    '& $price': {
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  discount: {
    textDecorationLine: 'line-through',
    color: '#879FB5',
  },
  price: {},
  priceTextColor: {
    color: '#4A647E',
  },
}));

export default function Price({ price }) {
  const classes = useStyles();

  return (
    !!price && (
      <Grid item xs={12} className={classes.priceWrap}>
        <Grid
          container
          alignItems="center"
          justify="flex-end"
          className={classes.root}
          spacing={2}
        >
          <Grid item>
            <Typography className={classes.discount}>
              Обычная цена : {Math.floor((price * 1.3) / 100) * 100 + 90} р.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={clsx(classes.price, classes.priceTextColor)}
              component="span"
            >
              Экономия 30%:
            </Typography>{' '}
            <Typography
              className={classes.price}
              component="span"
              color="primary"
            >
              {price} р.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  );
}
