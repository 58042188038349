import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../ui/TextField';
import PhoneInput from '../ui/PhoneInput';
import Title from '../Title';
import clsx from 'clsx';
import Block from '../Block';

const errorMessage = error => {
  switch (error) {
    case 'banned':
      return 'Заблокировано';
    case 'required':
      return 'Обязательно';
    default:
      return 'Ошибка';
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,

    transition: '.3s',
    opacity: 0,
    visibility: 'hidden',
    minHeight: 81,
  },
  open: {
    opacity: 1,
    visibility: 'visible',
  },
  contentWrap: {
    padding: '0 16px',
    height: 'calc(100% - 20px)',
    margin: 0,
    width: '100%',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  btn: {
    height: 48,
    width: 48,
    borderRadius: 8,
    padding: 0,
    background: '#F7F7F9',
    transition: '.3s',

    position: 'absolute',
    right: 8,
    bottom: 8,

    '&:hover': {
      background: '#DCF1DA',
      '& $icon': {
        color: '#00652E',
      },
    },
  },
  input: {
    textAlign: 'center',
    padding: 0,
  },
  icon: {},
  disabled: {
    background: '#F7F7F9 !important',
    opacity: 0.5,
  },
}));

export default function SendCode({
  setPhone,
  phone,
  setPhoneFull,
  setCodeSent,
  codeSent,
  error,
  setError,
  setUserCode,
}) {
  const classes = useStyles();

  function checkError(error) {
    if (error && error.sendCode) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (error) {
        setError({ ...error, sendCode: null });
      }
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [error, setError]);

  useEffect(() => {
    const length = phone.replace(/\D+/g, '').length;
    if (length === 11) {
      setPhoneFull(true);
      setUserCode({ user_phone: phone.replace(/\D+/g, '') });
    } else {
      setPhoneFull(false);
      setCodeSent(false);
    }
  }, [phone, setPhoneFull, setCodeSent, setUserCode]);

  function onChange(e) {
    setPhone(e.target.value);
  }

  return (
    <Block
      alignItems="stretch"
      alignContent="stretch"
      error={checkError(error)}
      className={clsx(classes.root, {
        [classes.open]: !codeSent,
      })}
      borderNone
    >
      <Grid item xs={12}>
        <Title error={checkError(error)}>
          {checkError(error) ? errorMessage(error.sendCode) : 'Номер телефона'}
        </Title>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.contentWrap}
        >
          <Grid item xs={12}>
            <div className={classes.content}>
              <TextField
                InputProps={{
                  inputComponent: PhoneInput,
                  disableUnderline: true,
                  classes: {
                    input: classes.input,
                  },
                }}
                name="phone"
                type="tel"
                value={phone}
                onChange={onChange}
                autoComplete="off"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Block>
  );
}
