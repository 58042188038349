import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Calendar } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    transition: '.3s',
    opacity: 0,
    visibility: 'hidden',

    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',

    '& .MuiPickersCalendarHeader-switchHeader': {
      padding: '0 8px',
      marginTop: 0,
      marginBottom: 4,
    },
    '& .MuiPickersCalendarHeader-transitionContainer p': {
      fontSize: '1rem',
      fontWeight: 'normal',
    },
    '& .MuiPickersCalendarHeader-daysHeader': {
      borderBottom: 'none',
      paddingBottom: 0,
      '& span:nth-child(7)': {
        color: '#FFD400',
      },
    },
    '& .MuiPickersCalendar-transitionContainer': {
      minHeight: 180,
      marginTop: 4,
      overflow: 'hidden',
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      width: 32,
      margin: '0 6px',
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 'normal',
      textTransform: 'uppercase',
      letterSpacing: 0.28,
      color: theme.palette.primary.main,
    },
  },
  open: {
    opacity: 1,
    visibility: 'visible',
  },
  day: {
    '& .MuiPickersDay-day': {
      width: 42,
      height: 28,
      position: 'relative',
      borderRadius: 10,
      margin: '1px 1px',

      '& p': {
        fontSize: '1rem',
        fontWeight: 'normal',
      },
    },
    '& .MuiPickersDay-daySelected': {
      fontWeight: 600,

      '&:before': {
        background: theme.palette.primary.main,
      },
    },
    '& .MuiPickersDay-day.MuiPickersDay-dayDisabled p': {
      color: '#98A9BC',
      fontWeight: 300,
    },
  },

  dayWeekends: {
    '& .MuiPickersDay-day': {
      '&:hover': {
        backgroundColor: '#FFF3B3',
      },
      '& p': {
        color: '#FFD400',
      },
    },
    '& .MuiPickersDay-day.MuiPickersDay-dayDisabled p': {
      color: '#FFF3B3',
    },
  },

  otherMonth: {
    '& .MuiPickersDay-day': {
      opacity: 1,
      color: '#ECEBED',
    },
  },
  otherMonthDayWeekends: {
    '& .MuiPickersDay-day p': {
      color: '#FFF3B3',
    },
  },
}));

export default function CalendarView({ open, ...props }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.open]: open,
      })}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Calendar
          {...props}
          disablePast
          allowKeyboardControl={false}
          renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
            return (
              <span
                className={clsx(classes.day, {
                  [classes.dayWeekends]: day.getDay() === 0,
                  [classes.otherMonth]: !dayInCurrentMonth,
                  [classes.otherMonthDayWeekends]:
                    !dayInCurrentMonth && day.getDay() === 0,
                })}
              >
                {dayComponent}
              </span>
            );
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
