import React, { useState } from 'react';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import ru from 'date-fns/locale/ru';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import { makeStyles, styled } from '@material-ui/core/styles';
import clsx from 'clsx';
import ChevronLeftRounded from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded';
import SwipeableViews from 'react-swipeable-views';

const WEEKS_CNT = 6;
const DAYS_CNT = 7;

const ArrowBtnWrap = styled(Grid)(({ theme }) => ({
  maxWidth: 40,
  flexBasis: 40,
}));

const ItemsWrap = styled(Grid)(({ theme }) => ({
  maxWidth: 'calc(100% - 80px)',
  flexBasis: 'calc(100% - 80px)',
}));

const ItemWrap = styled(Grid)(({ theme }) => ({
  maxWidth: 'calc(100% / 7)',
  flexBasis: 'calc(100% / 7)',
  height: 59,
  [theme.breakpoints.down('sm')]: {
    height: 63,
  },
}));

const ArrowBtn = styled(ButtonBase)(({ theme }) => ({
  height: '100%',
  width: '100%',
  borderRadius: 10,
}));

const useStylesDay = makeStyles(theme => ({
  dayOfWeek: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: 0.28,
    marginBottom: 4,
  },
  day: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
  },
  btn: {
    padding: '4px 0 8px 0',
    width: '100%',
    borderRadius: 10,
  },
  active: {
    background: theme.palette.primary.main,
    borderRadius: 10,

    '&:after': {
      position: 'absolute',
      content: '""',
      left: '50%',
      bottom: 0,
      transform: 'translate(-50%, 5px)',
      background: theme.palette.primary.main,
      border: '2px solid #FFFFFF',
      borderRadius: 4,
      width: 20,
      height: 10,
    },

    '& $dayOfWeek': {
      color: '#FFFFFF',
    },
    '& $day': {
      color: '#FFFFFF',
    },
  },
}));

function Day({ date: dateChecked, onClick, index, ...props }) {
  const classes = useStylesDay();

  const currentDay = new Date();
  const newDay = addDays(currentDay, index);
  const currentDateFormat = format(dateChecked, 'd L yyyy');
  const newDateFormat = format(newDay, 'd L yyyy');
  const dayOfWeek = format(newDay, 'EEEEEE', { locale: ru });
  const day = format(newDay, 'd', { locale: ru });
  const active = currentDateFormat === newDateFormat;

  return (
    <ItemWrap item {...props}>
      <ButtonBase
        className={clsx(classes.btn, {
          [classes.active]: active,
        })}
        onClick={() => onClick(newDay)}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              className={classes.dayOfWeek}
              align="center"
              color="primary"
            >
              {dayOfWeek}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.day} align="center">
              {day}
            </Typography>
          </Grid>
        </Grid>
      </ButtonBase>
    </ItemWrap>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    transition: '.3s',
    opacity: 0,
    visibility: 'hidden',

    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  open: {
    opacity: 1,
    visibility: 'visible',
  },
  swipeable: {},
  toggleBtn: {
    margin: 'auto',
    display: 'flex',
    padding: '0 6px',
    marginTop: -6,
  },
  toggle: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    color: '#879FB5',
  },
  toggleIconWrap: {
    marginLeft: 2,

    '& $toggleIcon': {
      color: '#879FB5',
      fontSize: theme.typography.pxToRem(16),
    },
  },
  toggleIcon: {},
}));

export default function DateView({ open, setOpen, date, setDate }) {
  const classes = useStyles();
  const [week, setWeek] = useState(0);

  function onPrev() {
    if (week !== 0) {
      setWeek(week - 1);
    }
  }

  function onNext() {
    if (week !== WEEKS_CNT - 1) {
      setWeek(week + 1);
    }
  }

  return (
    <Grid
      container
      className={clsx(classes.root, {
        [classes.open]: open,
      })}
    >
      <Grid item xs={12}>
        <Grid container>
          <ArrowBtnWrap item>
            <ArrowBtn disabled={week === 0} onClick={onPrev}>
              <ChevronLeftRounded />
            </ArrowBtn>
          </ArrowBtnWrap>
          <ItemsWrap item>
            <SwipeableViews
              className={classes.swipeable}
              index={week}
              onChangeIndex={index => setWeek(index)}
            >
              {Array.from({ length: WEEKS_CNT }, (_, weekIndex) => (
                <Grid container key={weekIndex}>
                  {Array.from({ length: DAYS_CNT }, (_, index) => (
                    <Day
                      key={index}
                      index={index + DAYS_CNT * weekIndex}
                      date={date}
                      onClick={setDate}
                    />
                  ))}
                </Grid>
              ))}
            </SwipeableViews>
          </ItemsWrap>
          <ArrowBtnWrap item>
            <ArrowBtn onClick={onNext} disabled={week === WEEKS_CNT - 1}>
              <ChevronRightRounded />
            </ArrowBtn>
          </ArrowBtnWrap>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.toggleBtn}
          endIcon={<ExpandMoreRounded className={classes.toggleIcon} />}
          onClick={setOpen}
          classes={{ endIcon: classes.toggleIconWrap }}
        >
          <Typography align="center" className={classes.toggle}>
            раскрыть календарь
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
