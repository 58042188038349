import React, { useState, useEffect, useRef } from 'react';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import getTime from 'date-fns/getTime';
import Grid from '@material-ui/core/Grid';
import DateTimeView from './DateTimeView';
import Duration from './Duration';
import DateTimePicker from '../DateTimePicker';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Block from '../Block';
import { duration as hours } from '../../lib/time';

const useStyles = makeStyles(theme => ({
  block: {
    position: 'static',
  },
  root: {
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
  },
  background: {
    visibility: 'hidden',
    opacity: 0,
    transition: '.3s',
    background: '#ffffff',
    borderRadius: 10,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  backgroundOpen: {
    visibility: 'visible',
    opacity: 0.7,
  },
}));

export default function Timing({
  setTimingParams,
  setOpenDateTimePicker,
  openDateTimePicker,
  duration: hoursInit,
  variants,
  deliveryTime,
}) {
  const classes = useStyles();
  const dateTimePickerRef = useRef();

  const currentDate = new Date();
  const minDate = addMinutes(currentDate, deliveryTime || 15);

  const [date, setDate] = useState(minDate);
  const dateFormat = format(date, 'yyyy-MM-dd');
  const timeFormat = format(date, 'HHmm');

  const [isChanged, setIsChanged] = useState(false);
  const [duration, setDuration] = useState(hoursInit);

  useEffect(() => {
    const interval = setInterval(() => {
      const defaultDate = new Date();
      const currentDateTime = getTime(defaultDate);
      const newDateTime = getTime(date);

      if (currentDateTime + (deliveryTime || 15) * 60000 > newDateTime) {
        const newData = addMinutes(defaultDate, deliveryTime || 15);
        setDate(newData);
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [date, deliveryTime]);

  useEffect(() => {
    setTimingParams({
      type: 'add',
      payload: {
        order_date: dateFormat,
        ordertime: timeFormat,
        is_now2: isChanged ? 0 : 1,
      },
    });
    setTimingParams({
      type: 'addJSON',
      payload: {
        car_minutes: duration,
      },
    });
  }, [setTimingParams, duration, dateFormat, timeFormat, isChanged]);

  function onClose() {
    setOpenDateTimePicker(false);
  }

  function onChange(newDate) {
    setIsChanged(true);
    setDate(newDate);
  }

  return (
    <Grid item xs={12}>
      <Block size="small" className={classes.block}>
        <Grid item xs={6} className={classes.root} ref={dateTimePickerRef}>
          <DateTimeView
            currentDate={currentDate}
            date={date}
            setDate={e => setDate(e)}
            openCalendar={() => setOpenDateTimePicker(!openDateTimePicker)}
            isChanged={isChanged}
            open={openDateTimePicker}
            deliveryTime={deliveryTime}
          />
          <DateTimePicker
            open={openDateTimePicker}
            setDate={onChange}
            onClose={onClose}
            anchorEl={dateTimePickerRef.current}
          />
        </Grid>
        <Grid item xs={6}>
          <Duration
            cnt={duration}
            onChange={e => setDuration(e)}
            postfix={['ч.', 'ч.', 'ч.', 'мин']}
            variants={variants || hours}
            disableBtns={
              (variants && variants.length === 1) ||
              (hours && hours.length === 1)
            }
          />
        </Grid>
        <div
          className={clsx(classes.background, {
            [classes.backgroundOpen]: openDateTimePicker,
          })}
        />
      </Block>
    </Grid>
  );
}
