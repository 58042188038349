import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  default: {
    verticalAlign: 'middle',
    fill: 'currentColor',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  light: {
    color: '#8A8AA8',
  },
}));

export default ({ className, color }) => {
  const classes = useStyles();
  return (
    <svg
      className={clsx(classes.default, className, {
        [classes.primary]: !color || color === 'primary',
        [classes.light]: color === 'light',
      })}
      width="12"
      height="18"
      viewBox="0 0 12 18"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.00001C0 2.69167 2.69167 0 6.00001 0C9.30836 0 12 2.69167 12 5.99998C12 6.99314 11.7517 7.9779 11.2815 8.84837L6.32812 17.8066C6.26221 17.926 6.13659 18 6.00001 18C5.86343 18 5.73782 17.926 5.6719 17.8066L0.720351 8.85132C0.248309 7.9779 0 6.99318 0 6.00001Z"
        fill="#50B848"
      />
      <circle cx="6" cy="6" r="3" fill="white" />
    </svg>
  );
};
