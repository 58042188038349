import React, { useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded';

const useStyles = makeStyles(theme => ({
  notchedOutline: {
    borderColor: '#EAECF1',
  },
  roundNormal: {
    borderRadius: 10,
  },
  roundSmall: {
    borderRadius: 6,
  },
  labelDefault: {
    color: '#D0C9D6',
  },
  outlinedInputRoot: {
    '&:hover $notchedOutline': {
      borderColor: theme.colors.green,
    },
    '&$outlinedFocused $notchedOutline': {
      borderWidth: 1,
    },
    '&$disabled $notchedOutline': {
      borderColor: '#EAECF1',
    },
  },
  outlinedFocused: {},

  selectFocused: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  disabled: {},

  icon: {
    color: theme.palette.primary.main,
    transition: '.3s',
  },
  iconActive: {
    transform: 'rotate(-180deg)',
  },

  menuRoot: {
    boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
    borderRadius: 6,
    marginTop: 8,
  },
}));

const TextField = forwardRef(function(
  { InputProps, InputLabelProps, SelectProps, ...other },
  ref,
) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { className, variant, round } = other;
  const isOutlined = variant === 'outlined';
  const { classes: InputLabelPropsClasses } = InputLabelProps || {};

  return (
    <MuiTextField
      {...other}
      className={clsx(className, classes.default)}
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          ...InputLabelPropsClasses,
          root: clsx(
            classes.labelDefault,
            InputLabelPropsClasses && InputLabelPropsClasses.root,
          ),
        },
      }}
      InputProps={{
        ...InputProps,
        classes: {
          root: clsx({
            [classes.outlinedInputRoot]: isOutlined,
          }),
          focused: clsx({
            [classes.outlinedFocused]: isOutlined,
          }),
          notchedOutline: clsx({
            [classes.notchedOutline]: isOutlined,
            [classes.roundNormal]: !round || round === 'normal',
            [classes.roundSmall]: round === 'small',
          }),
          disabled: classes.disabled,
          input: clsx(InputProps.classes && InputProps.classes.input, {
            [classes.roundNormal]: !round || round === 'normal',
            [classes.roundSmall]: round === 'small',
          }),
        },
      }}
      ref={ref}
      SelectProps={{
        ...SelectProps,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),
        classes: {
          select: classes.selectFocused,
          icon: clsx(classes.icon, {
            [classes.iconActive]: open,
          }),
        },
        IconComponent: ExpandMoreRounded,
        MenuProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          classes: {
            paper: classes.menuRoot,
          },
        },
      }}
    />
  );
});

export default TextField;
