import React from 'react';
import MuiPopover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: 'none',
    overflow: 'unset',
    background: 'none',
    [theme.breakpoints.down('sm')]: {
      top: '50% !important',
      left: '50% !important',
      transform: 'translate(-50%, -50%) !important',
    },
  },
}));

export default function Popover(props) {
  const classes = useStyles();

  return (
    <MuiPopover
      {...props}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      classes={{ paper: classes.paper }}
    />
  );
}
