import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  default: {
    verticalAlign: 'middle',
    fill: 'currentColor',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  light: {
    color: '#E3EAFA',
  },
}));

export default ({ className, color }) => {
  const classes = useStyles();
  return (
    <svg
      className={clsx(classes.default, className, {
        [classes.primary]: color === 'primary',
        [classes.light]: !color || color === 'light',
      })}
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path d="M7.99966 0C3.58197 0 0 3.58197 0 7.99966C0 12.4174 3.58197 16 7.99966 16C12.4174 16 16 12.4174 16 7.99966C16 3.58197 12.4174 0 7.99966 0ZM9.66502 12.3984C9.25325 12.5609 8.92546 12.6842 8.67962 12.7695C8.43445 12.8549 8.14933 12.8975 7.82493 12.8975C7.32648 12.8975 6.93841 12.7756 6.6621 12.5325C6.38578 12.2894 6.2483 11.9812 6.2483 11.6067C6.2483 11.4611 6.25845 11.3121 6.27877 11.1604C6.29977 11.0087 6.33295 10.838 6.37833 10.6463L6.89371 8.8259C6.93909 8.65117 6.97837 8.48525 7.00952 8.33084C7.04068 8.17507 7.05558 8.03217 7.05558 7.90214C7.05558 7.67052 7.00749 7.50798 6.912 7.41655C6.81515 7.32512 6.63297 7.28042 6.3614 7.28042C6.22866 7.28042 6.09185 7.30006 5.95166 7.34138C5.81283 7.38404 5.69228 7.42265 5.5934 7.46057L5.72952 6.89981C6.06679 6.76233 6.38984 6.64449 6.69799 6.54696C7.00614 6.44808 7.29735 6.39932 7.57164 6.39932C8.06671 6.39932 8.44868 6.51987 8.71754 6.75826C8.98506 6.99733 9.11983 7.30819 9.11983 7.69016C9.11983 7.7694 9.11035 7.90891 9.09206 8.10802C9.07378 8.30781 9.03924 8.48999 8.98912 8.65727L8.47644 10.4723C8.43445 10.6179 8.39721 10.7845 8.36334 10.9708C8.33016 11.157 8.3139 11.2992 8.3139 11.3947C8.3139 11.6358 8.36741 11.8004 8.47577 11.8877C8.58277 11.9751 8.77037 12.0191 9.03585 12.0191C9.16114 12.0191 9.30133 11.9968 9.45981 11.9534C9.61693 11.9101 9.73071 11.8715 9.8025 11.8383L9.66502 12.3984ZM9.57426 5.03128C9.3352 5.25342 9.04737 5.36449 8.71077 5.36449C8.37486 5.36449 8.08499 5.25342 7.84389 5.03128C7.60415 4.80914 7.48292 4.53892 7.48292 4.22332C7.48292 3.9084 7.60483 3.6375 7.84389 3.41333C8.08499 3.18849 8.37486 3.07674 8.71077 3.07674C9.04737 3.07674 9.33587 3.18849 9.57426 3.41333C9.81333 3.6375 9.93321 3.9084 9.93321 4.22332C9.93321 4.5396 9.81333 4.80914 9.57426 5.03128Z" />
    </svg>
  );
};
