import React from 'react';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../Title';
import Block from '../Block';
import num2str from '../../lib/num2str';

const useStyles = makeStyles(theme => ({
  wrap: {
    minHeight: 81,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: '100%',
    borderRadius: 10,
  },
  root: {
    flexGrow: 1,
  },
  fullDate: {
    flexGrow: 1,
    alignItems: 'stretch',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  date: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
  },
  subtitle: {
    color: '#50B848',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
  },

  dateTimeResult: {
    flexGrow: 1,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:first-child': {
        borderRight: '1px solid #F7F7F9',
      },
      '&:last-child': {
        borderLeft: '1px solid #F7F7F9',
      },
    },
  },
}));

export default function DateTimeView({
  currentDate,
  date,
  setDate,
  open,
  openCalendar,
  isChanged,
  deliveryTime,
  ...props
}) {
  const classes = useStyles();

  const dateValue = format(date, 'dd.MM', { locale: ru });
  const hourValue = format(date, 'HH');
  const minuteValue = format(date, 'mm');

  function dateResult() {
    if (currentDate.getMonth() === date.getMonth()) {
      if (currentDate.getDate() === date.getDate()) {
        return `Сегодня, ${hourValue}:${minuteValue}`;
      }
      if (currentDate.getDate() + 1 === date.getDate()) {
        return `Завтра, ${hourValue}:${minuteValue}`;
      }
    }
    return `${dateValue}, ${hourValue}:${minuteValue}`;
  }

  return (
    <Block
      className={classes.wrap}
      borderNone
      alignItems="stretch"
      alignContent="stretch"
      {...props}
    >
      <Grid item xs={12}>
        <div className={open ? 'open' : 'close'} id="time" />
        <ButtonBase className={classes.content} onClick={openCalendar}>
          <Title>Дата и время</Title>
          <Grid
            container
            alignContent="stretch"
            alignItems="stretch"
            justify="center"
            direction="column"
            className={classes.root}
          >
            {isChanged ? (
              <Grid item xs={12} className={classes.fullDate}>
                <Grid container className={classes.dateTimeResult}>
                  <Grid item xs={6}>
                    <Typography className={classes.date}>
                      {dateValue}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.date}>
                      {hourValue}:{minuteValue}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Typography className={classes.date}>{dateResult()}</Typography>
                <Typography className={classes.subtitle}>
                  подача через {deliveryTime}{' '}
                  {num2str(deliveryTime, ['минута', 'минуты', 'минут'])}
                </Typography>
              </>
            )}
          </Grid>
        </ButtonBase>
      </Grid>
    </Block>
  );
}
