import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles(
  (theme) => ({
    roundNormal: {
      borderRadius: 8,
    },
    roundSmall: {
      borderRadius: 6,
    },
    roundLarge: {
      borderRadius: 10,
    },

    buttonDefault: {
      boxShadow: 'none',
      fontSize: '1.125rem',
      fontWeight: 500,
      height: 48,
      minWidth: 180,
      padding: '0 24px',
    },
    buttonSmall: {
      fontSize: '0.875rem',
      height: 32,
      minWidth: 80,
      padding: '0 12px',
    },
    buttonLarge: {
      fontSize: '1.125rem',
      height: 64,
      minWidth: 260,
      padding: '0 32px',
    },
    buttonExtraLarge: {
      fontSize: '1.125rem',
      minHeight: 96,
      height: 'auto',
      minWidth: 512,
      padding: '0 32px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
      },
    },
    buttonContainedPrimary: {
      '&$disabled': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '&:hover': {
        background: theme.palette.primary.main,
        boxShadow: theme.palette.primary.shadow,
      },
      '&:active': {
        background: theme.palette.primary.main,
        boxShadow: 'none',
      },
    },
    buttonContainedSecondary: {
      '&$disabled': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      },
      '&:hover': {
        background: theme.palette.secondary.background,
        boxShadow: theme.palette.secondary.shadow,
      },
      '&:active': {
        background: theme.palette.secondary.background,
        boxShadow: 'none',
      },
    },
    buttonContainedLight: {
      background: theme.colors.white,
      color: '#000000',
    },
    buttonOutlinedPrimary: {
      '&$disabled': {
        borderColor: theme.palette.primary.main,
        color: '#ffffff',
      },
      background: '#ffffff',
    },
    buttonOutlinedPrimaryLight: {
      borderColor: '#DCF1DA',
      color: '#393836',
      background: '#ffffff',
    },
    buttonOutlinedSecondary: {
      background: '#ffffff',
      '&$disabled': {
        borderColor: theme.palette.secondary.main,
        color: '#ffffff',
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.backgroundLight,
      },
    },
    buttonOutlinedLight: {
      background: 'transparent',
      borderColor: '#ffffff',
      color: '#ffffff',
    },
    rippleOutlinedPrimary: {
      backgroundColor: theme.palette.primary.ripple,
    },
    rippleOutlinedSecondary: {
      backgroundColor: theme.palette.secondary.ripple,
    },
    default: {
      transition: theme.transitions.create('all', {
        easing: 'linear',
        duration: theme.transitions.duration.standard,
      }),

      '&$disabled': {
        boxShadow: 'none',
        opacity: '.4',
      },
      '&$loading': {
        opacity: 1,
      },
      textTransform: 'none',
    },

    disabled: {},

    loading: {},
  }),
  {
    name: 'GFButton',
  },
);

const useLoaderStyles = makeStyles((theme) => ({
  loader: {
    margin: '0 auto',
  },
  dot: {
    width: 8,
    height: 8,
    display: 'inline-block',
    backgroundColor: '#ffffff',
    animation: '$loader 1s infinite',
    borderRadius: '50%',

    '&:nth-child(1)': {
      animationDelay: '.48s',
    },
    '&:nth-child(2)': {
      margin: '0 16px',
      animationDelay: '.24s',
    },
    '&:nth-child(3)': {
      animationDelay: '0s',
    },
  },

  '@keyframes loader': {
    '0%': { transform: 'translate3d(0, -4px, 0)' },
    '50%': { transform: 'translate3d(0, 4px, 0)' },
    '100%': { transform: 'translate3d(0, -4px, 0)' },
  },
}));

function Loading() {
  const classes = useLoaderStyles();
  return (
    <div className={classes.loader}>
      <div className={classes.dot}></div>
      <div className={classes.dot}></div>
      <div className={classes.dot}></div>
    </div>
  );
}

// TODO: Перписать с MuiButton на MuiButtonBase

export default forwardRef(function GFButton(
  { round = true, loading, children, ...other },
  ref,
) {
  const classes = useStyles();

  const {
    className,
    classes: propClasses,
    href,
    variant,
    color,
    size,
    disabled,
  } = other;
  const isLink = !!href;
  const isText = !variant || variant === 'text';
  const isContained = variant === 'contained';
  const isOutlined = variant === 'outlined';
  const isPrimary = color === 'primary';
  const isPrimaryLight = color === 'primaryLight';
  const isSecondary = color === 'secondary';
  const isLight = color === 'light';

  return (
    <MuiButton
      {...other}
      classes={{
        ...propClasses,
        disabled: clsx(
          classes.disabled,
          loading && classes.loading,
          propClasses && propClasses.disabled,
        ),
      }}
      className={clsx(className, classes.default, {
        [classes.linkPrimaryText]: isLink && isText && isPrimary,
        [classes.linkPrimaryOther]: isLink && isText && isPrimary,
        [classes.linkSecondaryText]: isLink && isText && isSecondary,
        [classes.linkSecondaryOther]: isLink && isText && isSecondary,
        [classes.linkInherit]: (isLink || isText) && color === 'inherit',
        [classes.linkDefault]: (isLink || isText) && color === 'default',
        [classes.linkPrimary]: (isLink || isText) && !isContained && isPrimary,
        [classes.roundNormal]: round === 'normal' || round === true,
        [classes.roundSmall]: round === 'small',
        [classes.roundLarge]: round === 'large',
        [classes.buttonDefault]: isContained || isOutlined,
        [classes.buttonSmall]: size === 'small',
        [classes.buttonLarge]: size === 'large',
        [classes.buttonExtraLarge]: size === 'extraLarge',
        [classes.buttonContainedPrimary]: isContained && isPrimary,
        [classes.buttonContainedSecondary]: isContained && isSecondary,
        [classes.buttonContainedLight]: isContained && isLight,
        [classes.buttonOutlinedPrimary]: isOutlined && isPrimary,
        [classes.buttonOutlinedPrimaryLight]: isOutlined && isPrimaryLight,
        [classes.buttonOutlinedSecondary]: isOutlined && isSecondary,
        [classes.buttonOutlinedLight]: isOutlined && isLight,
      })}
      disabled={loading || disabled}
      ref={ref}
      TouchRippleProps={{
        classes: {
          child: clsx({
            [classes.rippleOutlinedPrimary]: isOutlined && isPrimary,
            [classes.rippleOutlinedSecondary]: isOutlined && isSecondary,
          }),
        },
      }}
    >
      {loading ? <Loading /> : children}
    </MuiButton>
  );
});
