import openGeocoder from 'node-open-geocoder';

export function getPriceParams(getPrice, car_id) {
  const order_c_auto = getPrice.prices[car_id][0].client.price_total;
  const price_add_hour_totla =
    getPrice.prices[car_id][0].client.price_add_hour_total;
  const price_JSON = JSON.stringify(getPrice.prices[car_id][0]);
  const jtoken_price_gr = btoa(price_JSON);
  const discount_proc_used = getPrice.prices[car_id][0].discount_proc_used;
  const control_distance = parseFloat(getPrice.control_distance);
  const total_distance = getPrice.route_summary.total_distance;
  const dist =
    control_distance > total_distance ? control_distance : total_distance;
  const price_hash = getPrice.prices[car_id][0].hash;
  const promo_discount_price = getPrice.prices[car_id][0].promo_discount_price;

  return {
    order_c_auto,
    jtoken_price_gr,
    discount_proc_used,
    dist,
    price_hash,
    promo_discount_price,
    price_add_hour_totla,
  };
}

export function getAddress(lng, lat, callback) {
  openGeocoder()
    .reverse(lng, lat)
    .end((_, res) => {
      if (res) {
        return callback(res);
      }
      return callback();
    });
}

export function addMarker(dispatchMarkers, index, lngLat) {
  dispatchMarkers({
    type: 'change',
    payload: {
      [index]: [lngLat.lng, lngLat.lat],
    },
  });
}

export function addAddress(
  address,
  lngLat,
  dispatchLoc,
  loc,
  dispatchStreetsQuery,
  dispatchHousesQuery,
  index,
) {
  const { lat, lng } = lngLat;
  const currentLoc = `${lat},${lng}`;

  const street = address.road;
  const house = address.house_number;

  const valueStreet = {
    id: 0,
    loc: currentLoc,
    name: street,
    city: address.state,
  };
  const valueHouse = {
    id: 0,
    loc: currentLoc,
    name: house,
    city: address.state,
  };

  if (house) {
    dispatchLoc({
      type: 'change',
      payload: {
        ...loc,
        [index]: {
          ...loc[index],
          street: valueStreet,
          house: valueHouse,
          manual: 1,
          lngLat,
        },
      },
    });
    dispatchStreetsQuery({
      type: 'change',
      payload: { [index]: street },
    });
    dispatchHousesQuery({
      type: 'change',
      payload: { [index]: house },
    });
  } else {
    if (street) {
      dispatchLoc({
        type: 'change',
        payload: {
          ...loc,
          [index]: {
            ...loc[index],
            street: valueStreet,
            house: '',
            manual: 1,
            lngLat,
          },
        },
      });
      dispatchStreetsQuery({
        type: 'change',
        payload: { [index]: street },
      });
      dispatchHousesQuery({
        type: 'delete',
        payload: index,
      });
    } else {
      dispatchLoc({
        type: 'change',
        payload: {
          ...loc,
          [index]: {
            ...loc[index],
            street: `${parseFloat(lat).toFixed(5)},${parseFloat(lng).toFixed(
              5,
            )}`,
            house: '',
            manual: 1,
            lngLat,
          },
        },
      });
      dispatchStreetsQuery({
        type: 'change',
        payload: {
          [index]: `${parseFloat(lat).toFixed(5)},${parseFloat(lng).toFixed(
            5,
          )}`,
        },
      });
      dispatchHousesQuery({
        type: 'delete',
        payload: index,
      });
    }
  }
}

export function setAddress({
  e,
  loc,
  index,
  dispatchLoc,
  dispatchStreetsQuery,
  dispatchHousesQuery,
  lngLat: lngLatProp,
  setStatus,
}) {
  const lngLat = lngLatProp ? lngLatProp : e.lngLat;
  const { lat, lng } = lngLat;

  getAddress(lng, lngLatProp ? lat - 0.0001 : lat, res => {
    if (res) {
      const address = res.address;

      addAddress(
        address,
        lngLat,
        dispatchLoc,
        loc,
        dispatchStreetsQuery,
        dispatchHousesQuery,
        index,
      );
    } else {
      setStatus('faildLoad');
    }
  });
}

export function delay(f, ms) {
  return function() {
    setTimeout(() => f.apply(this, arguments), ms);
  };
}
