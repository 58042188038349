import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
// import Info from '../icons/Info';
import { showParams, showLifting } from '../../lib/convert';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      '& $content': {
        '&:before': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& $subtitle': {
        color: theme.palette.primary.main,
      },
    },
  },
  title: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(14),
    transition: '.3s',
  },
  subtitle: {
    color: '#879FB5',
    fontSize: theme.typography.pxToRem(12),
    marginTop: 4,
    fontWeight: 300,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  imgWrap: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  img: {
    height: 40,
  },

  content: {
    padding: '0 8px 12px 8px',
    position: 'relative',
    height: '100%',

    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 'calc(100% - 20px)',
      border: '1px solid #EEF2F7',
      borderRadius: 5,
      left: 0,
      bottom: 0,
      zIndex: -1,
      transition: '.3s',
    },
  },

  rippleVisible: {
    backgroundColor: '#DCF1DA',
  },
}));

export default function FleetList({ item, dev, ...props }) {
  const classes = useStyles();

  return (
    <ButtonBase
      {...props}
      component={Grid}
      item
      xs={6}
      TouchRippleProps={{
        classes: {
          child: classes.rippleVisible,
        },
      }}
      className={classes.root}
    >
      <Grid container className={classes.content}>
        <Grid item xs={12}>
          <div className={classes.imgWrap}>
            <img
              src={`${
                dev
                  ? `/images/cars/${item.id}.png`
                  : `https://order-widget.gruzovichkof.ru/images/cars/${item.id}.png`
              }`}
              alt={item.name}
              className={classes.img}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.text}>
            <Typography variant="caption" className={classes.title}>
              {item.name}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.text}>
            {showParams(item.length, item.height, item.width) &&
              showLifting(item.lifting) && (
                <Typography
                  variant="textButtonUltraSmall"
                  className={classes.subtitle}
                >
                  {showParams(item.length, item.height, item.width)}{' '}
                  {showLifting(item.lifting)}
                </Typography>
              )}
          </div>
        </Grid>
      </Grid>
    </ButtonBase>
  );
}
