import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from './ui/Button';
import ClearRounded from '@material-ui/icons/ClearRounded';
import {
  Map as ReactMapboxGl,
  ZoomControl,
  Layer,
  Feature,
} from 'react-mapbox-gl';
import ClickAwayListener from './ClickAwayListener';
import { setAddress, addMarker } from '../lib/functions';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 0,
    visibility: 'hidden',
    opacity: 0,
    marginTop: 4,
    position: 'relative',
    height: 346,
    width: '100%',
    top: 0,
    left: 0,
    transition: '.3s',

    overflow: 'hidden',
    background: '#ffffff',
    border: '2px solid #F2F7FB',
    borderRadius: 10,

    [theme.breakpoints.down('sm')]: {
      top: -2,
      height: 'calc(100% - 2px)',
      position: 'absolute',
    },
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 72,
    zIndex: 1,
    visibility: 'hidden',
    opacity: 0,

    padding: '0 16px',
    minWidth: 'auto',
    height: 28,
    borderRadius: 4,

    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(14),

    [theme.breakpoints.down('sm')]: {
      top: 248,
      right: 16,
      bottom: 'auto',
    },
  },
  closeIcon: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
  },
  startIcon: {},
  open: {
    visibility: 'visible',
    opacity: 1,
  },
  map: {},
  control: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(74, 100, 126, 0.1) !important',
    border: 'none !important',
    top: '12px !important',
    right: '12px !important',
    borderRadius: '4px !important',

    '& button': {
      width: '28px !important',
      height: '28px !important',

      '&#zoomIn': {
        backgroundImage: enableDev =>
          `url(${
            enableDev
              ? '/images/controlPlus.svg'
              : 'https://order-widget.gruzovichkof.ru/images/controlPlus.svg'
          }) !important`,
        backgroundPosition: 'center !important',
        backgroundSize: 'auto !important',
        backgroundRepeat: 'no-repeat !important',

        borderTopLeftRadius: '4px !important',
        borderTopRightRadius: '4px !important',

        borderBottom: 'none !important',
      },

      '&#zoomOut': {
        backgroundImage: enableDev =>
          `url(${
            enableDev
              ? '/images/controlMinus.svg'
              : 'https://order-widget.gruzovichkof.ru/images/controlMinus.svg'
          }) !important`,
        backgroundPosition: 'center !important',
        backgroundSize: 'auto !important',
        backgroundRepeat: 'no-repeat !important',

        borderBottomLeftRadius: '4px !important',
        borderBottomRightRadius: '4px !important',
      },
    },

    [theme.breakpoints.down('sm')]: {
      top: '180px !important',
      right: '16px !important',
    },
  },
  mobilePinWrap: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',

      '&:before': {
        content: '""',
        background: '#505050',
        width: 10,
        height: 4,
        filter: 'blur(5px)',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
  },
  mobilePin: {
    transition: '.3s',
    position: 'relative',
    zIndex: 1,
  },
  mobilePinAnimate: {
    animationName: '$pin',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },

  mobilePinWrapAnimate: {
    '&:before': {
      animationName: '$dot',
      animationDuration: '1s',
      animationIterationCount: 'infinite',
    },
  },

  '@keyframes pin': {
    '0%': {
      transform: 'translateY(-10px)',
    },
    '50%': {
      transform: 'translateY(0)',
    },
    '100%': {
      transform: 'translateY(-10px)',
    },
  },

  '@keyframes dot': {
    '0%': {
      width: 10,
      height: 4,
      filter: 'blur(5px)',
    },
    '50%': {
      width: 6,
      height: 3,
      filter: 'blur(3px)',
    },
    '100%': {
      width: 10,
      height: 4,
      filter: 'blur(5px)',
    },
  },
}));

const dev = process.env.NODE_ENV !== 'production';

const MapBox = ReactMapboxGl({
  accessToken:
    process.env.MAP_TOKEN ||
    'pk.eyJ1IjoiZ2V2b3JneWFuIiwiYSI6ImNrOHIyeGFyYzAzZHQzZWxvN2FlMGpyOXgifQ.sfaVJcGdC9fPVbomNnLFug',
  attributionControl: false,
});

const MAPBOX_STYLE = dev
  ? process.env.MAP_STYLE_URL
  : 'mapbox://styles/gevorgyan/ck8r55dm00vi51io8tyzx4vsw';

export default function Map({
  setOpenMap,
  open,
  loc,
  dispatchStreetsQuery,
  dispatchHousesQuery,
  dispatchLoc,
  focusInputLine: index,
  setFocusInputLine,
  dispatchInputFields,
  dispatchMarkers,
  markers,
  currentPosition,
  setStatus,
  className,
  enableDev,
  classes: classesProp,
}) {
  const classes = useStyles(enableDev);
  const inputIndex = useRef(0);
  const maxIndex = useRef(0);
  const [center, setCenter] = useState(currentPosition());
  const [zoom, setZoom] = useState(13);
  const [mooved, setMooved] = useState(false);
  const [addressAdded, setAddressAdded] = useState(false);
  const markersArray = Object.values(markers);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    inputIndex.current = index;
    maxIndex.current = markersArray.length;
  }, [index, markersArray.length]);

  function checkCenter(center) {
    if (Array.isArray(center)) {
      const lngLat = { lng: center[0], lat: center[1] };
      return lngLat;
    }

    return center;
  }

  useEffect(() => {
    if (mobile && !mooved && !addressAdded) {
      const timeout = setTimeout(() => {
        checkCenter(center);
        const lngLat = checkCenter(center);

        setAddress({
          lngLat,
          loc,
          index: inputIndex.current,
          dispatchLoc,
          dispatchStreetsQuery,
          dispatchHousesQuery,
          setStatus,
        });
      }, 1000);

      return () => {
        clearTimeout(timeout);
        setAddressAdded(true);
      };
    }
  }, [
    mobile,
    mooved,
    center,
    dispatchStreetsQuery,
    dispatchHousesQuery,
    dispatchLoc,
    inputIndex,
    loc,
    addressAdded,
    setStatus,
  ]);

  function onClick(e, index) {
    setCenter(e.lngLat);
    setAddress({
      e,
      loc,
      index,
      dispatchLoc,
      dispatchStreetsQuery,
      dispatchHousesQuery,
      setStatus,
    });
    addMarker(dispatchMarkers, index, e.lngLat);
    setFocusInputLine(index + 1);
    if (index > 1) {
      dispatchInputFields({
        type: 'change',
        payload: {
          [index]: {
            index: index,
          },
        },
      });
    }
  }

  function onDragEndMarker(e, index) {
    setAddress({
      e,
      loc,
      index,
      dispatchLoc,
      dispatchStreetsQuery,
      dispatchHousesQuery,
      setStatus,
    });
    addMarker(dispatchMarkers, index, e.lngLat);
  }

  function onMoveEndMobile(map) {
    setCenter(Object.values(map.getCenter()));

    setMooved(false);
  }

  function onZoomEndMobile(map) {
    setZoom(map.getZoom());
    setMooved(false);
  }

  function onMove() {
    setMooved(true);
    setAddressAdded(false);
  }

  return (
    <ClickAwayListener disabled={!open} onClickAway={() => setOpenMap(false)}>
      <div
        className={clsx(
          className,
          classesProp && classesProp.map,
          classes.root,
          {
            [classes.open]: open,
          },
        )}
      >
        <Hidden smUp>
          <MapBox
            // eslint-disable-next-line react/style-prop-object
            style={MAPBOX_STYLE}
            containerStyle={{
              height: '100%',
              width: '100%',
            }}
            center={center}
            zoom={[zoom]}
            className={classes.map}
            onMove={onMove}
            onZoom={() => setMooved(true)}
            onMoveEnd={onMoveEndMobile}
            onZoomEnd={onZoomEndMobile}
          >
            <div
              className={clsx(classes.mobilePinWrap, {
                [classes.mobilePinWrapAnimate]: mooved || !addressAdded,
              })}
            >
              <img
                src={
                  enableDev
                    ? '/images/pin.svg'
                    : 'https://order-widget.gruzovichkof.ru/images/pin.svg'
                }
                alt=""
                className={clsx(classes.mobilePin, {
                  [classes.mobilePinAnimate]: mooved || !addressAdded,
                })}
              />
            </div>
            <ZoomControl className={classes.control} />
            <Layer
              type="symbol"
              id="marker"
              layout={{
                'icon-allow-overlap': true,
                'icon-image': 'gruzf-pin-1',
              }}
            >
              {markersArray.map((position, index) => (
                <Feature
                  coordinates={position}
                  key={index}
                  draggable
                  onDragEnd={e => onDragEndMarker(e, index)}
                />
              ))}
            </Layer>
          </MapBox>
        </Hidden>
        <Hidden smDown>
          <MapBox
            // eslint-disable-next-line react/style-prop-object
            style={MAPBOX_STYLE}
            containerStyle={{
              height: '100%',
              width: '100%',
            }}
            center={center}
            zoom={[zoom]}
            className={classes.map}
            onClick={(_, e) => onClick(e, inputIndex.current)}
            onZoomEnd={map => setZoom(map.getZoom())}
          >
            <ZoomControl className={classes.control} />
            <Layer
              type="symbol"
              id="marker"
              layout={{
                'icon-allow-overlap': true,
                'icon-image': 'gruzf-pin-1',
              }}
            >
              {markersArray.map((position, index) => (
                <Feature
                  coordinates={position}
                  key={index}
                  draggable
                  onDragEnd={e => onDragEndMarker(e, index)}
                />
              ))}
            </Layer>
          </MapBox>
        </Hidden>
        <Button
          color="primary"
          variant="contained"
          className={clsx(classes.close, {
            [classes.open]: open,
          })}
          onClick={() => setOpenMap(false)}
          startIcon={<ClearRounded className={classes.closeIcon} />}
          classes={{ startIcon: classes.startIcon }}
        >
          закрыть
        </Button>
      </div>
    </ClickAwayListener>
  );
}
