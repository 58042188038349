import React from 'react';
import ReactDOM from 'react-dom';
import Widget from './components';

function init({
  element,
  className,
  cityId,
  carId,
  duration,
  enableDev,
  direction,
  orderEmailTo,
}) {
  ReactDOM.render(
    <Widget
      className={className}
      cityId={cityId}
      carId={carId}
      duration={duration}
      enableDev={enableDev}
      direction={direction}
      orderEmailTo={orderEmailTo}
    />,
    element,
  );
}

global.GFOrderWidget = { init };
