import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  default: {
    verticalAlign: 'middle',
    fill: 'currentColor',
  },
  primary: {
    color: theme.palette.primary.dark,
  },
  light: {
    color: '#879FB5',
  },
}));

export default ({ className, color }) => {
  const classes = useStyles();
  return (
    <svg
      className={clsx(classes.default, className, {
        [classes.primary]: color === 'primary',
        [classes.light]: !color || color === 'light',
      })}
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path d="M13.7667 6.76667H13.2942C13.1735 3.4783 10.5217 0.826467 7.23333 0.705833V0.233333C7.23333 0.1043 7.12903 0 7 0C6.87097 0 6.76667 0.1043 6.76667 0.233333V0.705833C3.4783 0.826467 0.826467 3.4783 0.705833 6.76667H0.233333C0.1043 6.76667 0 6.87097 0 7C0 7.12903 0.1043 7.23333 0.233333 7.23333H0.705833C0.826467 10.5217 3.4783 13.1735 6.76667 13.2942V13.7667C6.76667 13.8957 6.87097 14 7 14C7.12903 14 7.23333 13.8957 7.23333 13.7667V13.2942C10.5217 13.1735 13.1735 10.5217 13.2942 7.23333H13.7667C13.8957 7.23333 14 7.12903 14 7C14 6.87097 13.8957 6.76667 13.7667 6.76667ZM7.23333 12.8275V12.3667C7.23333 12.2376 7.12903 12.1333 7 12.1333C6.87097 12.1333 6.76667 12.2376 6.76667 12.3667V12.8275C3.73567 12.7073 1.29267 10.2643 1.1725 7.23333H1.63333C1.76237 7.23333 1.86667 7.12903 1.86667 7C1.86667 6.87097 1.76237 6.76667 1.63333 6.76667H1.1725C1.29267 3.73567 3.73567 1.29267 6.76667 1.1725V1.63333C6.76667 1.76237 6.87097 1.86667 7 1.86667C7.12903 1.86667 7.23333 1.76237 7.23333 1.63333V1.1725C10.2643 1.29267 12.7073 3.73567 12.8275 6.76667H12.3667C12.2376 6.76667 12.1333 6.87097 12.1333 7C12.1333 7.12903 12.2376 7.23333 12.3667 7.23333H12.8275C12.7073 10.2643 10.2643 12.7073 7.23333 12.8275Z" />
      <path d="M9.80013 6.7668H8.6148C8.5119 6.05303 7.94723 5.48836 7.23346 5.38546V4.20013C7.23346 4.0711 7.12916 3.9668 7.00013 3.9668C6.8711 3.9668 6.7668 4.0711 6.7668 4.20013V5.38546C6.05303 5.48836 5.48836 6.05326 5.38546 6.7668H4.20013C4.0711 6.7668 3.9668 6.8711 3.9668 7.00013C3.9668 7.12916 4.0711 7.23346 4.20013 7.23346H5.38546C5.48836 7.94723 6.05303 8.5119 6.7668 8.6148V9.80013C6.7668 9.92916 6.8711 10.0335 7.00013 10.0335C7.12916 10.0335 7.23346 9.92916 7.23346 9.80013V8.6148C7.94723 8.5119 8.5119 7.947 8.6148 7.23346H9.80013C9.92916 7.23346 10.0335 7.12916 10.0335 7.00013C10.0335 6.8711 9.92916 6.7668 9.80013 6.7668Z" />
    </svg>
  );
};
